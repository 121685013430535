import instance2 from "../Utils/instance2"

export const getFindings = body => {
  return instance2({
    method: 'get',
    url: 'findings/get.php',
    params: body
  })
}

export const getUnconformities = body => {
  return instance2({
    method: 'get',
    url: "/nonconformities/get.php",
    params: body
  })
}

export const getBaseFindingTimings = body => {
  return instance2({
    method: 'get',
    url: 'findings/timings',
    params: body
  })
}

export const editBaseFindingTimings = body => {
  return instance2({
    method: 'put',
    url: 'findings/timings',
    data: body
  })
}

export const createBaseFindingTimings = body => {
  return instance2({
    method: 'post',
    url: 'findings/timings',
    data: body
  })
}

export const deleteBaseFindingTimings = body => {
  return instance2({
    method: 'delete',
    url: 'findings/timings',
    data: body
  })
}

export const createFreeFinding = body => {
  return instance2({
    method: 'post',
    url: 'findings',
    data: body
  })
}

export const setFastFindingInfo = body => {
  return instance2({
    method: 'post',
    url: 'findings/fast_config',
    data: body
  })
}

export const assignFinding = (body, offline) => {
  const request = {
    method: 'put',
    url: 'findings/assign',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const solveFinding = (body, offline) => {
  const request = {
    method: 'put',
    url: 'findings/solve',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const approveFinding = (body, offline) => {
  const request = {
    method: 'put',
    url: 'findings/approve',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const dismissFinding = (body, offline) => {
  const request = {
    method: 'put',
    url: 'findings/dismiss',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const deleteFinding = (body, offline) => {
  const request = {
    method: 'delete',
    url: 'findings',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const getFindingFields = body => {
  return instance2({
    method: 'get',
    url: 'findings/fields',
    params: body
  })
}

export const createFindingField = body => {
  return instance2({
    method: 'post',
    url: 'findings/fields',
    data: body
  })
}

export const editFindingField = body => {
  return instance2({
    method: 'put',
    url: 'findings/fields',
    data: body
  })
}

export const deleteFindingField = body => {
  return instance2({
    method: 'delete',
    url: 'findings/fields',
    data: body
  })
}

export const createFieldOption = body => {
  return instance2({
    method: 'post',
    url: 'findings/fields/options',
    data: body
  })
}

export const editFieldOption = body => {
  return instance2({
    method: 'put',
    url: 'findings/fields/options',
    data: body
  })
}

export const deleteFieldOption = body => {
  return instance2({
    method: 'delete',
    url: 'findings/fields/options',
    data: body
  })
}

export const getFindingBaseForm = body => {
  return instance2({
    method: 'get',
    url: 'findings/form',
    params: body
  })
}

export const getFinding = body => {
  return instance2({
    method: 'get',
    url: 'findings/show',
    params: body
  })
}

export const updateFindingForm = (body, offline) => {
  const request = {
    method: 'put',
    url: 'findings/update_form',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const sendReminderAssignee = body => {
  return instance2({
    method: 'put',
    url: 'findings/reminder',
    data: body
  })
}

export const rejectFinding = (body, offline) => {
  const request = {
    method: 'put',
    url: 'findings/reject',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const createNewFinding = (body, offline) => {
  const request = {
    method: 'post',
    url: 'findings/new',
    data: body
  }
  if (offline) return request
  return instance2(request)
}

export const getFindingNotificationsHistory = body => {
  return instance2({
    method: 'get',
    url: 'findings/notifications',
    params: body
  })
}

export function getSpecificFindings(body) {
  return instance2.get("/findings/specific", { params: body })
}

export function checkForItemRepetition(body) {
  return instance2.post("/findings/check_repetition", body)
}

export function getFindingComments(body) {
  return instance2.get("/findings/comments", { params: body })
}

export function createFindingComment(body) {
  return instance2.post("/findings/comments", body)
}

export function editFindingComment(body) {
  return instance2.put("/findings/comments", body)
}

export function reEstimateFinding(body) {
  return instance2.put("/findings/re_estimate", body)
}

export function createEfficacyVerification(body) {
  return instance2.post("/findings/efficacy_verification", body)
}

export function updateEfficacyVerification(body) {
  return instance2.put("/findings/efficacy_verification", body)
}