import { IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { inline } from "../../Utils/defaultStyles"

function TextInput({ input, type, value, label, name, disabled, options = {}, required, onBlur, autoFocus, onKeyDown, positiveOnly, error, errorMsge, width = "100%", backgroundColor, min, margin, onChange }) {
    const [showPassword, setShowPassword] = useState(false)

    const handleMouseDown = (event) => {
        event.preventDefault()
    }

    function handleChange(event) {
        const { target } = event
        onChange(event)
        if (input?.label?.toLowerCase() === "Nombres y Apellidos".toLowerCase()) {
            const newEvent = new CustomEvent("userbyid", { detail: { name: target.value } })
            window.dispatchEvent(newEvent)
        }

    }

    const preventInvalidChars = (event) => {
        const { key, target } = event
        if (type === "number") {
            const isValidKey = /[0-9]/.test(key) || (key === "." && !target.value.includes("."))
            if (!isValidKey && key !== "Backspace" && key !== "ArrowLeft" && key !== "ArrowRight") {
                event.preventDefault()
            }
        }
    }


    const newValue = value < 0 ? "0" : value ? value : value === 0 ? "0" : value
    const currentValue = value ? value : value === 0 ? "0" : value

    return (
        <TextField
            value={positiveOnly ? newValue : currentValue}
            disabled={disabled}
            onChange={handleChange}
            label={`${label}${required ? "*" : ""}`}
            name={String(name)}
            variant="outlined"
            fullWidth
            sx={{
                margin: margin || "12px 0",
                width: width || '100%',
                borderRadius: "5px",
                boxShadow: `4px 4px 6px ${backgroundColor}`,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            type={type === "password" ? (!showPassword ? "password" : "text") : type}
            rows={options.rows}
            onBlur={onBlur}
            autoFocus={autoFocus}
            multiline={options.multiline || false}
            placeholder={options.placeholder}
            onKeyDown={(event) => {
                preventInvalidChars(event) // Bloquear caracteres no validos
                if (onKeyDown) onKeyDown(event)
            }}
            helperText={error && value ? <Typography variant="caption" style={{ color: "red" }}> {errorMsge} </Typography> : null}
            InputProps={{
                inputProps: {
                    ...(min !== undefined ? { min: min } : {}), // Si min está definido, lo incluimos
                },
                endAdornment: type === "password" ? (
                    <InputAdornment position="end">
                        <IconButton
                            onMouseDown={handleMouseDown}
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ) : null
            }}
        />
    )
}

export default TextInput