import { Box, Button, Dialog, Typography } from '@mui/material'
import { dialog_style, end_flex_buttons, inline_buttons, labeless, link_style, text_space, title_style } from '../../../Utils/defaultStyles'
import { useEffect, useMemo, useState } from 'react'
import { getSubdivisions } from '../../../API/subdivisions'
import { transformToOptions } from '../../../Utils/functions'
import SelectInput from '../../Inputs/SelectInput'
import { KeyboardArrowRight } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

function createCrumbs(selected_subdivision, subdivisions, hasBeenFound = false, base_subdivision_id = null) {
	const currentSubdivision = subdivisions.find(subdivision => (subdivision.id) === (selected_subdivision))
	if (!currentSubdivision) return []
	const parent = subdivisions.find(subdivision => (subdivision.id) === (currentSubdivision.parent_id))
	const isCurrentSubdivisionTheBase = (currentSubdivision.id) === (base_subdivision_id)
	const hasTheBaseBeenFoundInTheRecursion = (hasBeenFound || isCurrentSubdivisionTheBase) && !!base_subdivision_id
	currentSubdivision.selectable = !hasTheBaseBeenFoundInTheRecursion || isCurrentSubdivisionTheBase
	if (!parent) return [currentSubdivision]
	return [...createCrumbs(parent.id, subdivisions, hasTheBaseBeenFoundInTheRecursion, base_subdivision_id), currentSubdivision]
}

function SubdivisionShowDialog({ open, onClose, checklist, onSubmit, updateCrumbs, editable = false, subdivision, branch_id }) {

	const [fetched, setFetched] = useState(false)
	const [subdivisions, setSubdivisions] = useState([])
	const [selectedSubdivision, setSelectedSubdivision] = useState(null)
	const [crumbs, setCrumbs] = useState([])
	const { t } = useTranslation('cards')

	useEffect(() => {
		async function fetchData() {
			const response = await getSubdivisions({ branch_id, simple: true })
			setSubdivisions(response.data.info)
			setFetched(true)

			const has_base_subdivision = !!checklist?.base_subdivision_id
			if (has_base_subdivision) {
				const base_subdivision = response.data.info.find(subdivision => (subdivision.id) === (checklist.base_subdivision_id))
				setSelectedSubdivision(base_subdivision?.id || null)
				const current_crumbs = [{ name: checklist.branch_name, id: null, selectable: !has_base_subdivision }, ...createCrumbs(base_subdivision?.id, response.data.info, false, checklist?.base_subdivision_id)]
				setCrumbs(current_crumbs)
			}

			const has_selected_subdivision = !!checklist?.subdivision_id || !!subdivision.id
			if (has_selected_subdivision) {
				const subdivision_id = checklist?.subdivision_id || subdivision.id
				const selected_subdivision = response.data.info.find(subdivision => (subdivision.id) === (subdivision_id))
				setSelectedSubdivision(selected_subdivision?.id || null)
				const current_crumbs = [{ name: checklist?.branch_name, id: null, selectable: !has_base_subdivision }, ...createCrumbs(selected_subdivision?.id, response.data.info, false, checklist?.base_subdivision_id)]
				setCrumbs(current_crumbs)
			}

		}
		if (open && !fetched) fetchData()
	}, [open, fetched, checklist, subdivision, branch_id])

	useEffect(() => { updateCrumbs(crumbs) }, [crumbs, open])

	function onSelectSubdivision(event) {
		const { target } = event
		const subdivision_id = target.value
		const subdivision = subdivisions.find(subdivision => subdivision.id === subdivision_id)
		subdivision.selectable = true
		const newCrumbs = [...crumbs, subdivision]
		setCrumbs(newCrumbs)
		setSelectedSubdivision(subdivision_id)
	}

	function onResetSubdivision(crumb) {
		const index = crumbs.findIndex(item => (item.id) === (crumb.id))
		const newCrumbs = crumbs.slice(0, index + 1)
		setCrumbs(newCrumbs)
		setSelectedSubdivision(crumb.id)
	}

	function onSave() {
		const subdivision = subdivisions.find(subdivision => (subdivision.id) === (selectedSubdivision))
		onSubmit(subdivision)
		onClose()
	}

	const currentOptions = useMemo(() => {
		const currentChildren = subdivisions.filter(subdivision => (subdivision.parent_id) === (selectedSubdivision))
		return transformToOptions(currentChildren)
	}, [subdivisions, selectedSubdivision])

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={dialog_style}>
				<Typography sx={title_style} variant="h4">
					{t('subdivisionDialog.title')}
				</Typography>
				<Box sx={text_space}>
					<Box sx={inline_buttons}>
						{crumbs.map((crumb, index) => (
							<Box sx={inline_buttons} key={index}>
								{index > 0 && <KeyboardArrowRight style={{ margin: '0 6px ' }} />}
								{editable ? (
									<Typography
										color={!!crumb?.selectable ? 'info.main' : 'primary.main'}
										variant="h4"
										sx={crumb?.selectable ? link_style : ''}
										onClick={crumb?.selectable ? () => onResetSubdivision(crumb) : null}
									>
										{crumb?.name}
									</Typography>
								) : (
									<Typography color="info.main" variant="h4">
										{crumb?.name}
									</Typography>
								)}
							</Box>
						))}
					</Box>
				</Box>
				{editable && (
					<Box>
						<Typography sx={labeless} variant="subtitle2">
							{t('subdivisionDialog.selectLocationLabel')}
						</Typography>
						<SelectInput options={currentOptions} label="" onChange={onSelectSubdivision} />
						<Box sx={end_flex_buttons}>
							<Button variant="outlined" onClick={onClose}>
								{t('subdivisionDialog.cancelButton')}
							</Button>
							<Button color="success" variant="contained" onClick={onSave}>
								{t('subdivisionDialog.saveButton')}
							</Button>
						</Box>
					</Box>
				)}
			</Box>
		</Dialog>
	)
}

export default SubdivisionShowDialog