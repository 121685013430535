import instance2 from "../Utils/instance2"

export function getContractors(body) {
	return instance2.get("/contractors", { params: body })
}

export function syncContractors(body) {
	return instance2.get("/contractors/sync", { params: body })
}

export function getContractor(id) {
	return instance2.get(`/contractors/${id}`)
}

export function checkContractorValidation(id) {
	return instance2.get(`/contractors/${id}/check_validation`)
}

export function createContractor(body) {
	return instance2.post("/contractors", body)
}

export function updateContractor(body) {
	return instance2.put(`/contractors`, body)
}

export function deleteContractor(body) {
	return instance2.delete(`/contractors`, { params: body })
}

export function assignContractorBranches(body) {
	return instance2.put("/contractors/branches", body)
}

export function assignContractorUsers(body) {
	return instance2.put("/contractors/users", body)
}

export function getContractorDocumentsValidators(body) {
	return instance2.get("/contractors/validators", { params: body })
}

export function setContractorDocumentsValidators(body) {
	return instance2.put("/contractors/validators", body)
}

export function assignContractorCertificate(body) {
	return instance2.put("/contractors/certificate", body)
}

export function assignContractorUserCertificate(body) {
	return instance2.put("/contractors/user_certificate", body)
}

export function sendInvitation(body) {
	return instance2.post("/contractors/invitation", body)
}

export function askForVerification(body) {
	return instance2.post("/contractors/verification", body)
}

export function sendVerificationAlert(body) {
	return instance2.post("/contractors/verification_alert", body)
}

export function sendCertificateVerification(body) {
	return instance2.post("/contractors/approval", body)
}

export function getEntryApplications(body) {
	return instance2.get("/entry_applications", { params: body })
}

export function getEntryApplication(body) {
	return instance2.get(`/entry_applications/${body.id}`, { params: body })
}

export function createEntryApplication(body) {
	return instance2.post("/entry_applications", body)
}

export function updateEntryApplication(body) {
	return instance2.put("/entry_applications", body)
}

export function getContractorApplication(body) {
	return instance2.get(`/entry_applications/${body.id}`, { params: body })
}

export function getWorkerApplication(body) {
	return instance2.get(`/individual_entry_applications/${body.id}`, { params: body })
}

export function cloneIndividualEntryApplication(body) {
	return instance2.post("/individual_entry_applications", body)
}

export function createWorkerApplicationChecklist(body) {
	return instance2.post("/individual_entry_applications/checklist", body)
}

export function approveEntryApplication(body) {
	return instance2.put("/entry_applications/approve", body)
}

export function rejectEntryApplication(body) {
	return instance2.put("/entry_applications/reject", body)
}

export function approveWorkerApplication(body) {
	return instance2.put("/individual_entry_applications/approve", body)
}

export function rejectWorkerApplication(body) {
	return instance2.put("/individual_entry_applications/reject", body)
}

export function downloadEntryApplication(body) {
	return instance2.get("/entry_applications/download", { params: body, responseType: "blob" })
}

export function getContractorsGeneralInvitation(body) {
	return instance2.get("contractors/general_invitation", body)
}

export function editContractorsGeneralInvitation(body) {
	return instance2.put("contractors/general_invitation", body)
}