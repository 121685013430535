import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import TextField from "@mui/material/TextField"
import { inline_buttons } from "../../Utils/defaultStyles"
import SearchIcon from '@mui/icons-material/Search'
import { removeAccents } from "../../Utils/functions"
import { e } from "mathjs"

function SearchInput({ data = [], onFilterChange, specificKey, setValue = null, label = "Buscar...", icon = false, onKeyDown }) {
	const [searchValue, setSearchValue] = useState("")
	function handleSearchChange(event) {
		setSearchValue(event.target.value)
		if (setValue) setValue(event.target.value)
	}

	useEffect(() => {
		filterData()
	},
		[searchValue])

	function filterData() {
		const value = searchValue.toLowerCase()
		const filteredData = [...data]
		const result = filteredData.filter((entry) => {
			const entryValue = specificKey ? entry[specificKey] : Object.values(entry).join(" ")
			if (entryValue !== undefined) {
				return removeAccents(entryValue.toLowerCase()).includes(removeAccents(value))
			}
			return false
		})

		onFilterChange(result)
	}

	function handleKeyDown(event) {
		event.stopPropagation()
		if (onKeyDown) {
			if (event.key === "Enter" && searchValue.trim() !== "") {
				setSearchValue("")
				onKeyDown()
			}
		}
	}

	return (
		<Box sx={inline_buttons}>
			{icon && <SearchIcon sx={{ marginTop: 2 }} />}
			<TextField
				variant="standard"
				label={label}
				onChange={handleSearchChange}
				sx={{ marginLeft: 2 }}
				onKeyDown={handleKeyDown}
			/>
		</Box>
	)
}

export default SearchInput
