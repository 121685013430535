import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import MultiSelectInput from "../../../../Shared/Inputs/MultiSelectInput"
import { transformToExtraOptions } from "../../../../Utils/functions"
import { useTranslation } from "react-i18next"

function getArray(value) {
	if (value === "") return []
	try {
		return JSON.parse(value)
	} catch (error) {
		return []
	}
}

function MultiField({ field, onChange, value = [], onBlur }) {
	const options = field?.options || []
	const real_value = Array.isArray(value) ? value : getArray(value)
	const { finished, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	if (finished || !hasPermissions) return (
		<Box>
			<Box>
				{!!real_value?.length ?
					real_value.map((val, index) => <Typography key={index} variant='subtitle1'>{val}</Typography>) :
					<Typography variant='subtitle1'>{t('noAnswer')}</Typography>
				}
			</Box>
		</Box>
	)

	return (
		<Box>
			<MultiSelectInput onBlur={onBlur} value={real_value} onChange={onChange} label="" options={transformToExtraOptions(options)} />
		</Box>
	)
}

export default MultiField