import { Box, Dialog, IconButton, Typography } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import { ChecklistFieldContext } from '../ExtraFields'
import TextInput from "../../../../Shared/Inputs/TextInput"
import { HelpOutline } from "@mui/icons-material"
import { inline, dialog_style, title_style } from "../../../../Utils/defaultStyles"
import useToggle from "../../../../Hooks/ToogleHook"
import { evaluate } from 'mathjs'
import { useTranslation } from "react-i18next"

function replaceFormula(formula, fields, base_field, categories, t) {
	const unicodeExponents = {
		'²': '^2',
		'³': '^3',
	}

	Object.keys(unicodeExponents).forEach(exp => {
		const regex = new RegExp(exp, 'g')
		formula = formula?.replace(regex, unicodeExponents[exp])
	})

	formula = formula.replace(/([^<>=!])=([^=])/g, '$1==$2')

	const numberFields = fields.filter(field => field.field_type === 'number')

	const unique_fields = numberFields.filter(field => {
		const category = categories.find(cat => cat.id === field.checklist_field_category_id || !field.checklist_field_category_id)
		const field_without_category = !field.checklist_field_category_id
		return category
			? (category.id === base_field.checklist_field_category_id || category.allow_clone === false || field_without_category)
			: false
	})

	const no_unique_fields = numberFields.filter(numberField => {
		return !unique_fields.some(clonedField => clonedField.label === numberField.label)
	})

	const variables = formula?.match(/\$\s*[^$]+\s*\$/g) || []
	const groupVariables = formula?.match(/#\s*[^#]+\s*#/g) || []

	variables.forEach(variable => {
		const field = unique_fields.find(field => `$${field.label}$` === variable)
		if (field) {
			formula = formula.replaceAll(variable, field.value)
		} else {
			formula = formula.replaceAll(variable, 0)
		}
	})

	groupVariables.forEach(variable => {
		const fields = no_unique_fields.filter(field => `#${field.label}#` === variable)

		const values = fields.map(field => field.value)
		formula = formula.replaceAll(variable, `[${values.join(', ')}]`)
	})

	try {
		const result = evaluate(formula)
		return result
	} catch (error) {
		return t('calc.infoLack')
	}
}

function NumberField({ field, onChange, value, onBlur }) {
	const { finished, hasPermissions, checklist } = useContext(ChecklistContext)
	const { updateField } = useContext(ChecklistFieldContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const [conditions, setConditions] = useState([])
	const [colors, setColors] = useState([])
	const [currentColor, setCurrentColor] = useState(null)
	const { t } = useTranslation('checklistForm')

	const fields = useMemo(() => checklist?.fields?.filter(field => field.field_type === "number") || [], [checklist.fields])
	const categories = useMemo(() => { return checklist?.checklist_field_categories || [] }, [checklist.checklist_field_categories])

	useEffect(() => {
		const evaluateConditions = () => {
			for (let i = conditions.length - 1; i >= 0; i--) {
				const condition = conditions[i]
				const result = replaceFormula(condition, fields, field, categories, t)
				if (result && !!field.value) {
					setCurrentColor(colors[i])
					break
				} else {
					setCurrentColor("#FFFFFF")
				}
			}
		}
		if (conditions.length > 0 && colors.length > 0) {
			evaluateConditions()
		}
	}, [value, conditions, fields, field, categories])

	useEffect(() => {
		if (!finished) {
			field.compliance_observation = currentColor === "#FFFFFF" ? null : currentColor
			updateField(field, false)
		}
	}, [currentColor])

	useEffect(() => {
		if (field.compliance_case) {
			setConditions(field.compliance_case.split('$%&%$').map(cond => cond.split('%&%')[0] || ''))
			setColors(field.compliance_case.split('$%&%$').map(cond => cond.split('%&%')[1] || '#FFFFFF'))
		}
	}, [field.compliance_case])

	if (finished || !hasPermissions) {
		return (
			<Box>
				<Typography color={field.compliance_observation} variant='subtitle1'>{value}</Typography>
			</Box>
		)
	}

	return (
		<Box sx={{ padding: '8px', borderRadius: '4px' }}>
			<Box sx={inline}>
				<TextInput type="number" onBlur={onBlur} onChange={onChange} value={value} label="" backgroundColor={currentColor} />
				{conditions && (
					<IconButton color="info" onClick={toggleDialog}>
						<HelpOutline />
					</IconButton>
				)}
			</Box>
			<Dialog open={openDialog} onClose={toggleDialog} fullWidth maxWidth="md">
				<Box sx={dialog_style}>
					<Typography variant='h4' sx={title_style}>{t('calc.conditions')}</Typography>
					<Box sx={inline}>
						<Box>
							{conditions.map((condition, index) => (
								<Box key={index}>
									<Typography color={colors[index]} variant='subtitle1'>{condition}</Typography>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
			</Dialog>
		</Box>
	)
}

export default NumberField
