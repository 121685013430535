import { Collapse, IconButton, Tooltip } from '@material-ui/core'
import { AccessTimeOutlined, Block, CheckCircleOutline, AddCommentOutlined, CommentOutlined, Edit, MessageOutlined, PanoramaFishEye } from '@material-ui/icons'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../../../CurrentUser'
import useToggle from '../../../../Hooks/ToogleHook'
import newTheme from '../../../../newTheme'
import { commentAuthorization } from '../../../../API/authorizations'
import { callSnackbar } from '../../../../Utils/snackbar'
import BigTooltip from '../../../../Shared/Tooltip/BigTooltip'
import RegisterNewSignButton from '../../../../Shared/Buttons/RegisterNewSignButton'
import { inline_buttons, dialog_style, title_style, end_buttons } from '../../../../Utils/defaultStyles'
import RTE from '../../../../Shared/Inputs/RTE'
import { ChecklistContext } from '../ChecklistView'

const css = {
	user: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2,
		borderRadius: 1,
		background: "white"
	},
	userSigned: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2,
		borderRadius: 1,
		background: newTheme.palette.blue.light
	},
	comment: {
		background: 'white',
		borderRadius: 1,
		paddingLeft: 2,
		width: '80%'
	}
}

const AuthorizationCard = ({ disabled, user, signs, isSigned, toggleReject, authorization, handleSign, gotoSign, isAuthorized }) => {
	const { t } = useTranslation('checklistShow')
	const [comment, setComment] = useState(user.comment || '')
	const [openComment, toggleComment] = useToggle(false)
	const [openCollapse, toggleCollapse] = useToggle(false)
	const { currentUser } = useContext(CurrentUserContext)
	const { setRejecting } = useContext(ChecklistContext)

	useEffect(() => {
		const element = document.getElementById(`html-comment-${user.comment_id}`)
		if (!!element) {
			element.innerHTML = comment
		}
	}, [comment])

	async function onBlur() {
		const body = { comment, authorization_id: authorization.id, user_id: user.id }
		await commentAuthorization(body)
		callSnackbar(t('comments.commentSaved'), 'success')
	}

	function handleReject() {
		setRejecting(true)
		toggleReject()
	}

	const isCurrentUser = currentUser.id === String(user.id)
	const hasRegisteredSigns = currentUser.signs?.length > 0

	return (
		<Box>
			{disabled ? (
				<Box>
					<Box sx={css.user} style={{ opacity: 0.7 }}>
						<Box style={{ display: 'flex', alignItems: 'center' }}>
							<Block style={{ margin: '0 8px', color: newTheme.palette.grey.dark }} />
							<Box>
								<Typography variant="subtitle1">{user.name}</Typography>
								<Typography variant="caption">{t('authorizations.waitingForPreviousAuthorization')}</Typography>
							</Box>
						</Box>
						<Box sx={inline_buttons}>
							{comment && (
								<BigTooltip title={openCollapse ? t('comments.hideComment') : t('comments.showComment')}>
									<IconButton size="small" color="warning" variant="outlined" onClick={toggleCollapse}>
										{openCollapse ? <CommentOutlined /> : <MessageOutlined />}
									</IconButton>
								</BigTooltip>
							)}
							{isCurrentUser && !isSigned && (
								<Button color="warning" variant="outlined" onClick={toggleReject}>
									{t('authorizations.reject')}
								</Button>
							)}
							<Button color="primary" variant="contained" disabled>
								{t('authorizations.sign')}
							</Button>
						</Box>
					</Box>
					<Collapse in={openCollapse}>
						<Box sx={{ ...css.user, background: 'white', paddingLeft: 2 }}>
							<Box
								sx={{ ...css.comment, overflowWrap: 'break-word', wordWrap: 'break-word' }}
								className="ck-content"
								id={`html-comment-${user.comment_id}`}
							></Box>
						</Box>
					</Collapse>
				</Box>
			) : (
				<Box>
					<Box sx={isSigned ? css.userSigned : css.user}>
						<Box style={{ display: 'flex', alignItems: 'center' }}>
							{isSigned ? (
								<CheckCircleOutline style={{ margin: '0 8px', color: newTheme.palette.green.dark }} />
							) : (isAuthorized ? (
								<PanoramaFishEye style={{ margin: '0 8px', color: newTheme.palette.green.dark }} />
							) : (
								<AccessTimeOutlined style={{ margin: '0 8px', color: newTheme.palette.info.main }} />
							))}
							<Typography variant="subtitle1">{user.name}</Typography>
						</Box>
						<Box sx={inline_buttons}>
							{!comment && isCurrentUser && (
								<BigTooltip title={t('comments.addComment')}>
									<IconButton size="small" color="warning" variant="outlined" onClick={toggleComment}>
										<AddCommentOutlined />
									</IconButton>
								</BigTooltip>
							)}
							{comment && (
								<BigTooltip title={openCollapse ? t('comments.hideComment') : t('comments.showComment')}>
									<IconButton size="small" color="warning" variant="outlined" onClick={toggleCollapse}>
										{openCollapse ? <CommentOutlined /> : <MessageOutlined />}
									</IconButton>
								</BigTooltip>
							)}
							{isCurrentUser && !isSigned && !isAuthorized && (
								<Button color="warning" variant="outlined" onClick={handleReject}>
									{t('authorizations.reject')}
								</Button>
							)}
							{isSigned && (
								<Button
									color="secondary"
									variant="contained"
									onClick={() => gotoSign(signs.find((s) => s.user_id === user.id))}
								>
									{t('authorizations.viewSign')}
								</Button>
							)}
							{!isSigned && hasRegisteredSigns && !isAuthorized && (
								<Button
									color="primary"
									variant="contained"
									disabled={currentUser.id !== String(user.id)}
									onClick={() => handleSign(user)}
								>
									{t('authorizations.sign')}
								</Button>
							)}
							{!isSigned && !hasRegisteredSigns && (
								<RegisterNewSignButton
									afterRegister={() => handleSign(user)}
									disabled={currentUser.id !== String(user.id)}
								/>
							)}
						</Box>
					</Box>
					{comment && (
						<Collapse in={openCollapse}>
							<Box sx={{ ...css.user, background: 'white', paddingLeft: 4 }}>
								<Box
									sx={{ ...css.comment, overflowWrap: 'break-word', wordWrap: 'break-word' }}
									className="ck-content"
									id={`html-comment-${user.comment_id}`}
								></Box>
								{isCurrentUser && (
									<IconButton onClick={toggleComment} size="small">
										<Edit />
									</IconButton>
								)}
							</Box>
						</Collapse>
					)}
				</Box>
			)}
			<Dialog open={openComment} onClose={toggleComment} fullWidth maxWidth="sm">
				<Box sx={dialog_style}>
					<Typography variant="h4" sx={title_style}>
						{t('comments.comment')}
					</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Box sx={{ width: '97%' }}>
							<RTE value={comment} onChange={(e) => setComment(e.target.value)} onBlur={onBlur} />
						</Box>
					</Box>
					<Box sx={{ ...end_buttons, marginTop: 2 }}>
						<Button color="primary" variant="contained" onClick={toggleComment}>
							{t('authorizations.save')}
						</Button>
					</Box>
				</Box>
			</Dialog>
		</Box>
	)
}

export default AuthorizationCard
