import { useContext, useMemo, useRef } from "react"
import useToggle from "../../../../Hooks/ToogleHook"
import { MenuItem, Box, Typography, IconButton } from "@mui/material"
import { MoreVert } from "@material-ui/icons"
import InsideMenu from "./InsideMenu"
import { EntitySelectInputContext } from "../EntitySelectInput"
import newTheme from "../../../../newTheme"

const css = {
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		minHeight: 43
	},
	elipsis: {
		padding: 2,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		flex: 1,
		minWidth: 0,
	}
}

function Item({ item, isSelected, isMatch }) {

	const insideMenu = useRef(null)
	const [openInsideMenu, toggleInsideMenu] = useToggle(false)
	const { onSelect, field, updateEndpoint, deleteEndpoint } = useContext(EntitySelectInputContext)

	function onClickMenu(event) {
		event.stopPropagation()
		toggleInsideMenu()
	}

	const backgroundColor = useMemo(() => {
		if (isSelected) return newTheme.palette.primary.main + "12"
		if (isMatch) return newTheme.palette.info.main + "21"
		return "white"
	}, [isSelected, isMatch])

	return (
		<Box sx={{ width: '100%' }}>
			<MenuItem onClick={() => onSelect(item)} sx={{ padding: 0, background: backgroundColor }}>
				<Box ref={insideMenu} sx={css.container}>
					<Typography sx={css.elipsis} variant='subtitle1'>{item?.[field]}</Typography>
					{(!!updateEndpoint || !!deleteEndpoint) &&
						<IconButton onClick={(event) => { onClickMenu(event) }}>
							<MoreVert />
						</IconButton>
					}
				</Box>
			</MenuItem>
			<InsideMenu
				openMenu={openInsideMenu}
				menu={insideMenu}
				toggleMenu={toggleInsideMenu}
				item={item}
			/>
		</Box>
	)
}

export default Item