import { Box, Dialog } from '@mui/material'
import { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MySignaturePad from '../Signature/MySignaturePad'
import { addNameToB64, fromBase64ToFile } from '../../Utils/functions'
import moment from 'moment'
import { addUserSign, uploadFile } from '../../API/users'
import { callSnackbar } from '../../Utils/snackbar'
import { Clear } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { dialog_style, inline_title_no_wrap, loader_container, text_space } from '../../Utils/defaultStyles'
import LoadingDialog from '../Loader/LoadingDialog'
import { CurrentUserContext } from '../../CurrentUser'
import useToggle from '../../Hooks/ToogleHook'
import LoaderAnimator from '../Loader/LoaderAnimator'

const css = {
	pad: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}

function RegisterNewSignDialog({ open, toggleOpen, user, setUser, afterRegister = () => { } }) {
	const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
	const { t } = useTranslation("dialogs")
	const [loading, setLoading] = useState(false)
	const userToUse = user || currentUser
	const signs = userToUse?.signs || []
	const [openDialog, toggleDialog] = useToggle(signs.length === 0)

	const openToUse = open ?? openDialog
	const toggleToUse = () => {
		if (toggleOpen) {
			toggleOpen()
		} else {
			toggleDialog()
		}
	}

	const handleSaveNewSign = async (value) => {
		const b64file = addNameToB64(value, userToUse.name + signs.length)
		setLoading(true)
		const file = await fromBase64ToFile(b64file)
		const file_body = new FormData()
		file_body.append('file', file)
		file_body.append('name', userToUse.name + signs.length)
		const file_response = await uploadFile(file_body)
		const file_url = file_response.data.info
		const body = {
			user_id: userToUse.id,
			firma: file_url,
			sign_name: userToUse.name,
			date_sign: moment().format('YYYY-MM-DD'),
			url: file_url
		}
		const response = await addUserSign(body)
		if (setUser) {
			const new_signs = [...signs, response.data.info]
			setUser({ ...userToUse, signs: new_signs })
		} else if (setCurrentUser) {
			const updatedUser = { ...currentUser, signs: [...currentUser.signs, response.data.info] }
			setCurrentUser(updatedUser)
			console.trace("THIS IS CALLING")
			localStorage.setItem("account", JSON.stringify(updatedUser))
			window.dispatchEvent(new CustomEvent("update_account"))
		}
		setLoading(false)
		callSnackbar(t('feedback.signAdded'), "success")
		afterRegister(response.data.info)
		toggleToUse()
	}

	if (!userToUse) return null

	return (
		<Dialog open={openToUse} onClose={toggleToUse} fullWidth maxWidth="md">
			<Box sx={dialog_style}>
				<Box sx={inline_title_no_wrap}>
					<Typography variant='h1' color="info.main" >{t('actions.registerSign')}</Typography>
					{signs.length > 0 && (
						<IconButton onClick={toggleToUse}>
							<Clear />
						</IconButton>
					)}
				</Box>
				<Typography variant='subtitle2' sx={text_space}>
					No tienes ninguna firma registrada en Tazki, te invitamos a dibujar a continuación tu firma que
					quedará en los documentos que firmes posteriormente
				</Typography>
				{loading ? (
					<Box sx={loader_container}>
						<LoaderAnimator />
					</Box>
				) : (
					<Box sx={css.pad}>
						<MySignaturePad clearButton="true" save={handleSaveNewSign} submitText={t('actions.registerSign')} />
					</Box>
				)}
			</Box>
		</Dialog>
	)
}

export default RegisterNewSignDialog
