import { Box, Dialog, IconButton, Typography } from "@mui/material"
import { useContext, useEffect, useMemo } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import { evaluate } from "mathjs"
import { HelpOutline } from "@mui/icons-material"
import useToggle from "../../../../Hooks/ToogleHook"
import { dialog_style, inline_space, title_style } from "../../../../Utils/defaultStyles"
import { useTranslation } from "react-i18next"

const css = {
	input: {
		margin: "12px 0 12px 12px",
	}
}

function replaceFormula(format, formula, fields, base_field, categories, t) {
	const unicodeExponents = {
		'²': '^2',
		'³': '^3',
	}

	let formatParts = {}

	if (format) {
		formatParts = format.split('|').reduce((acc, part) => {
			const [key, value] = part.split('=')
			switch (key) {
				case 'decimals':
					acc[key] = isNaN(value) ? 2 : parseInt(value)
					break
				case 'thousandsSeparator':
					acc[key] = value === "true"
					break
				default:
					acc[key] = value
					break
			}

			return acc
		}, {})
	}

	const { prefix = '', suffix = '', decimals = 2, thousandsSeparator = false } = formatParts

	Object.keys(unicodeExponents).forEach(exp => {
		const regex = new RegExp(exp, 'g')
		formula = formula?.replace(regex, unicodeExponents[exp])
	})

	const numberFields = fields.filter(field => field.field_type === 'number')

	const unique_fields = numberFields.filter(field => {
		const category = categories.find(cat => cat.id === field.checklist_field_category_id || !field.checklist_field_category_id)
		return category
			? (category.id === base_field.checklist_field_category_id || category.allow_clone === false)
			: false
	})

	const no_unique_fields = numberFields.filter(numberField => {
		return !unique_fields.some(clonedField => clonedField.label === numberField.label)
	})


	const variables = formula?.match(/\$\s*[^$]+\s*\$/g) || []
	const groupVariables = formula?.match(/#\s*[^#]+\s*#/g) || []

	// find variables fields to get the values
	variables.forEach(variable => {
		const field = unique_fields.find(field => `$${field.label}$` === variable)
		if (field) {
			formula = formula.replaceAll(variable, field.value)
		} else {
			formula = formula.replaceAll(variable, 0)
		}
	})

	groupVariables.forEach(variable => {
		const fields = no_unique_fields.filter(field => `#${field.label}#` === variable)

		// For group cases the values should be replaced as an array
		const values = fields.map(field => field.value)
		formula = formula.replaceAll(variable, `[${values.join(', ')}]`)
	})


	try {
		let result = evaluate(formula)
		let formattedResult = result.toFixed(decimals)
		if (thousandsSeparator) {
			formattedResult = formattedResult.toLocaleString('en-US')
		}
		formattedResult = `${prefix}${formattedResult}${suffix}`

		return { result, formattedResult }

	} catch (error) {
		return { rawResult: null, result: t('calc.infoLack') }
	}
}

function CalcField({ field, onChangeAndSave, value }) {
	const { finished, hasPermissions, checklist } = useContext(ChecklistContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const { t } = useTranslation('checklistForm')

	const fields = useMemo(() => { return checklist?.fields?.filter(field => field.field_type === "number") || [] }, [checklist.fields])
	const categories = useMemo(() => { return checklist?.checklist_field_categories || [] }, [checklist.checklist_field_categories])

	const { result, formattedResult } = useMemo(() => replaceFormula(field.compliance_observation, field.compliance_case, fields, field, categories, t), [fields])

	useEffect(() => {
		const answered = !!field.value
		const sameAsResult = field.value === result
		const isEmptyAnswer = result === t('calc.infoLack')

		// Should update if the results have changed, if it doesnt have answer or if the answer is not empty
		const shouldUpdate = (!answered || !sameAsResult) && !isEmptyAnswer

		if (shouldUpdate) {
			const event = { target: { value: result, name: field.label } }
			onChangeAndSave(event)
		}
	}, [result])

	if (finished || !hasPermissions) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<Box sx={inline_space}>
				<Typography variant='subtitle1'>{formattedResult}</Typography>
				<IconButton sx={{ marginRight: "7px" }} color="info" onClick={toggleDialog}>
					<HelpOutline />
				</IconButton>
			</Box>
			<Dialog open={openDialog} onClose={toggleDialog} fullWidth maxWidth="md">
				<Box sx={dialog_style}>
					<Typography variant='h4' sx={title_style} >{t('calc.formula')}</Typography>
					<Typography variant='subtitle1'>{field.compliance_case}</Typography>
				</Box>
			</Dialog>
		</Box>
	)
}

export default CalcField