import { Box, Paper, Dialog, Typography } from '@mui/material'
import { useContext, useEffect, useState, useMemo } from 'react'
import { ChecklistContext } from '../ChecklistView'
import { getSupplies } from '../../../../API/supplies'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import SuppliesSelector from './SuppliesSelector'
import AppContext from '../../../../AppContext'
import { DatabaseManagerContext } from '../../../../DatabaseManagers/DatabaseManager'
import { SuppliesManagerContext } from '../../../../DatabaseManagers/SupplyManager'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		padding: 3,
		margin: '24px 0'
	}
}

function ChecklistSupplies({ onEdit, finished }) {

	const { checklist } = useContext(ChecklistContext)
	const [supplies, setSupplies] = useState([])
	const { online: { status: online } } = useContext(AppContext)
	const { indexedDBReady } = useContext(DatabaseManagerContext)
	const { getSupplies: getLocalSupplies } = useContext(SuppliesManagerContext)
	const { t } = useTranslation('checklistShow')

	useEffect(() => {
		async function fetchData() {
			const response = await getSupplies({ checklist_id: checklist.id })
			setSupplies(response.data.info)
		}
		async function fetchDataOffline() {
			const response = await getLocalSupplies()

			const localSupplies = response
			const supplyTypeIds = checklist?.supply_types?.map(type => type.id) || []
			const filteredLocalSupplies = localSupplies.filter(sup => supplyTypeIds.includes(sup.supply_type_id))
			setSupplies(filteredLocalSupplies)
		}
		if (online) fetchData()
		else fetchDataOffline()

	}, [checklist.id, indexedDBReady])

	const isFinishedWithoutSupply = useMemo(() => finished && !checklist.supply_id, [checklist])
	const isNotSupplySelected = useMemo(() => !checklist.supply_id, [checklist]);

	if (!checklist.supply_types?.length) return null
	const suppliesOptions = supplies.map(sup => ({ label: `${sup.code} - ${sup?.supply_type.name}`, value: sup.id }))

	function handleChange(event) {
		const { target } = event
		const body = { id: checklist.id, supply_id: target.value }
		onEdit(body)
	}

	if (isFinishedWithoutSupply) return null
	if (!supplies.length) return null

	return (
		<Box>
			<SuppliesSelector open={isNotSupplySelected} options={suppliesOptions} checklist={checklist} handleChange={handleChange} disabled={finished} />
			<Paper sx={css.container}>
				<Typography variant='h4'>{t('supplies.relacionated')} *</Typography>
				<SelectInput disabled={finished} value={checklist.supply_id} label="" options={suppliesOptions} onChange={handleChange} />
			</Paper>
		</Box>
	)
}

export default ChecklistSupplies