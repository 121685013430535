import instance2 from "../Utils/instance2"

export function getBaseFindingMeasures(body) {
	return instance2.get('base_finding_measures', { params: body })
}

export function createBaseFindingMeasure(body) {
	return instance2.post('base_finding_measures', body)
}

export function bulkCreateBaseFindingMeasure(body) {
	return instance2.post('base_finding_measures/bulk_create', body)
}

export function editBaseFindingMeasure(body) {
	return instance2.put(`base_finding_measures/${body.id}`, body)
}

export function deleteBaseFindingMeasure(body) {
	return instance2.delete(`base_finding_measures/${body.id}`, { data: body })
}
