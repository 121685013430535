import { Box, Button, Typography } from '@mui/material'
import { createContext, useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { createChecklist, getChecklist, setChecklistSupply } from '../../../API/checklists'
import { changeMetadata, rejectChecklist, updateChecklistSubdivision } from '../../../API/new_checklists'
import AppContext from '../../../AppContext'
import { signAuthorization } from '../../../API/authorizations'
import { callSnackbar } from '../../../Utils/snackbar'
import { ChecklistManagerContext } from '../../../DatabaseManagers/ChecklistsManager'
import moment from 'moment'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { end_flex_buttons, italic, paper_style, section_separation, title_style } from '../../../Utils/defaultStyles'
import LoaderAnimator from '../../../Shared/Loader/LoaderAnimator'
import LoadingDialog from '../../../Shared/Loader/LoadingDialog'
import SecondaryInfo from './SecondaryInfo'
import ChecklistStructure from './ChecklistStructure'
import ChecklistSupplies from './Supplies/ChecklistSupplies'
import ContentSelector from './ContentSelector'
import FinishedCard from './Cards/FinishedCard'
import Authorizations from './Authorizations'
import Comments from './Comments'
import LoadingCard from './Cards/LoadingCard'
import ErrorCard from './Cards/ErrorCard'
import ConectionDisclaimer from '../../../Shared/OnlineStatusRendered/ConectionDisclaimer'
import { DatabaseManagerContext } from '../../../DatabaseManagers/DatabaseManager'
import { CheckIfFieldsAreAnswered, CheckIfItemsAreAnswered, CheckIfSignsAreReady, CheckIfChecklistAreDone, CheckIfIpaIsReady } from './checklistUtils'
import IfOnline from '../../../Shared/OnlineStatusRendered/IfOnline'
import IfOffline from '../../../Shared/OnlineStatusRendered/IfOffline'
import { transformBodyAndSendCallback } from '../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../DatabaseManagers/ActionsManager'
import { UserSelectContext } from '../../../Shared/Dialogs/UserSelectDialog'
import { CurrentUserContext } from '../../../CurrentUser'
import RelatedChecklistQuickAccess from './RelatedChecklists/RelatedChecklistQuickAccess'
import { useTranslation } from 'react-i18next'
import FinishNotification from './FinishNotification'
import useWebSocket from '../../../Hooks/UseWebSocket'
import RejectionCommentField from './RejectionField/RejectionCommentField'


const css = {
	container: {
		margin: 3,
		display: 'flex',
		gap: 4,
		flexWrap: 'wrap',
		position: 'relative',
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		minHeight: 400,
		'@media (max-width:780px)': {
			display: 'block'
		}
	},
	mainContainer: {
		maxWidth: 800
	},
	end_button: {
		maxWidth: 800,
		textAlign: 'end'
	},
	leftContainer: {
		maxWidth: 800,
		flexBasis: 800,
		flexGrow: 1
	}
}

export const ChecklistContext = createContext({})

function ChecklistView({ match }) {

	const [checklist, setChecklist] = useState({})
	const { account: user, online: { status: online }, history } = useContext(AppContext)
	const { getChecklist: getLocalChecklist, addChecklist } = useContext(ChecklistManagerContext)
	const { getUsersObjects } = useContext(UserSelectContext)
	const [isResponsible, setIsResponsible] = useState(false)
	const [highlighted, setHighlighted] = useState(null)
	const [loading, setLoading] = useState(false)
	const [readyForAnother, setReady] = useState(false)
	const [saveTimeout, setSaveTimeout] = useState(0)
	const [throwError, setError] = useState(false)
	const [dialogLoading, setDialogLoading] = useState(false)
	const [noChecklist, setNoChecklist] = useState(false)
	const actionContext = useActionContext()
	const { currentUser } = useContext(CurrentUserContext)
	const currentChecklistId = useRef(null)
	const location = useLocation()
	const [rejecting, setRejecting] = useState(false)
	const [rejectionComment, setRejectionComment] = useState('')
	const goBackId = location.state?.goBackId
	const enterprise = localStorage.getItem("enterprise")
	const ipa_percentage = enterprise ? JSON.parse(enterprise).ipa_percentage : true

	const isAdmin = user.userType === 1
	const responsible = getUsersObjects(checklist.user_id)
	const { indexedDBReady } = useContext(DatabaseManagerContext)
	const { t } = useTranslation('checklistShow')

	const checklistId = useMemo(() => checklist.id, [checklist.id])

	const onReceiveNewSign = useCallback((data) => {
		const newSign = data?.sign
		if (!newSign) return

		const groupId = newSign.free_group_id
		const newChecklist = { ...checklist }
		const signGroups = newChecklist.group_signs || []
		const signGroup = signGroups.find(group => group.id === groupId)
		if (!signGroup) return

		const signs = signGroup.signs || []

		if ([newSign.id, newSign.temp_id].some(id => signs.some(sign => sign.id === id))) return

		const newSigns = [...signs, newSign]
		const newSignGroup = { ...signGroup, signs: newSigns }
		const newSignGroups = [...signGroups.filter(group => group.id !== groupId), newSignGroup]
		newChecklist.group_signs = newSignGroups
		setAsyncChecklist(newChecklist)
	}, [checklist])
	const channelParams = useMemo(() => ({ channel: 'ChecklistSignsChannel', checklist_id: checklistId }), [checklistId])
	useWebSocket(channelParams, onReceiveNewSign)

	useEffect(() => {
		async function fetchData() {
			const id = match.params.id
			currentChecklistId.current = id
			setHighlighted(null)
			if (online) {
				setLoading(true)
				const response = await getChecklist({ id }).catch(() => setNoChecklist(true))
				setLoading(false)
				setChecklist(response.data.info)
				addChecklist(response.data.info)
			} else {
				if (indexedDBReady) {
					let response = await getLocalChecklist(id)
					response = await updateRelatedChecklistStatus(response)
					setChecklist(response || {})
				}
			}
		}
		fetchData()
	}, [match.params.id, indexedDBReady])

	useEffect(() => {
		setIsResponsible(user.id === checklist.user_id)
	}, [checklist, user.id])

	useEffect(() => {
		if (!!highlighted) { searchAndHighlighPending() }
	}, [checklist])

	async function updateRelatedChecklistStatus(checklist) {
		const related_checklists = checklist.related_checklists || []
		for (const related_checklist of related_checklists) {
			const offlineChecklist = await getLocalChecklist(related_checklist.id)
			if (offlineChecklist) {
				const newChecklist = { ...offlineChecklist, status: offlineChecklist.date_done != "0000-00-00" ? t('checklistView.status.finished') : t('checklistView.status.scheduled') }
				checklist = { ...checklist, related_checklists: [...checklist.related_checklists.filter(c => c.id !== offlineChecklist.id), newChecklist] }
			}
		}
		return checklist
	}

	function setAsyncChecklist(checklist_body) {
		addChecklist(checklist_body)
		if (String(currentChecklistId.current) === String(checklist_body.id)) setChecklist(checklist_body)
	}

	async function onRejecChecklist(motive) {
		const body = { motive, id: checklist.id }
		const response = await rejectChecklist(body)
		if (response.data.status !== "success") return
		callSnackbar(t('checklistView.rejected'), "success")
		const rejection = response.data.info
		const new_checklist = { ...checklist }
		const new_rejections = new_checklist.rejections || []
		new_rejections.push(rejection)
		new_checklist.rejections = new_rejections
		new_checklist.date_done = "0000-00-00"
		new_checklist.time_done = "00:00:00"
		new_checklist.rejection_motive = motive
		new_checklist.rejection_date = moment().format("YYYY-MM-DD HH:mm")
		new_checklist.rejection_user_name = user.name
		setAsyncChecklist(new_checklist)
	}

	async function onRestart() {
		const body = {
			user_id: checklist.user_id,
			date_scheduled: moment(new Date()).format("YYYY-MM-DD"),
			preventive_measure_id: checklist.preventive_measure_id,
			activity_id: checklist.activity_id,
			branch_id: checklist.branch_id,
		}
		setLoading(true)
		const response = await createChecklist(body)
		if (response.data.status !== "success") return setLoading(false)
		const newChecklist = response.data.info
		history.push(`/checklists/${newChecklist.id}`)
		setLoading(false)
		currentChecklistId.current = newChecklist.id
		setAsyncChecklist(newChecklist)
	}

	async function setMetadata(metadata) {
		const body = {
			id: checklist.id,
			metadata
		}
		const newChecklist = { ...checklist }
		const response = await changeMetadata(body)
		newChecklist.metadata = response.data.info
		setChecklist(newChecklist)
	}

	async function onFinish() {
		const new_checklist = {
			...checklist,
			date_done: moment().format("YYYY-MM-DD"),
			time_done: moment().format("HH:mm")
		}
		setAsyncChecklist(new_checklist)
		appendSave()
		await transformBodyAndSendCallback(new_checklist, "close_checklist", actionContext, t('checklistView.finishChecklist'))
		resolveSave()
		setReady(true)
		openChecklistInForm()
	}

	function openChecklistInForm() {
		const isFormBeforeItems = checklist.invert_order === 1
		const tab = isFormBeforeItems ? 0 : 1
		if (goBackId) history.push({ pathname: `/checklists/${goBackId}`, search: `?tab=${tab}` })
	}

	async function editSupply(body) {
		const new_checklist = { ...checklist }
		new_checklist.supply_id = body.supply_id
		setChecklist(new_checklist)
		addChecklist(new_checklist)
		appendSave()
		await setChecklistSupply(body)
		resolveSave()
	}

	async function onSignAuthorization(body) {
		const { data } = body
		setDialogLoading(true)
		const response = await signAuthorization(data)
		setDialogLoading(false)
		setChecklist(response.data.info)
	}

	async function updateSubdivision(body) {
		const response = await updateChecklistSubdivision(body)
		setChecklist(response.data.info)
	}

	function searchAndHighlighPending() {
		const disabled_categories = checklist.metadata.disabled_categories || []
		const items = checklist.checklist_items2
		const fields = checklist.fields

		let to_highligh = null

		const areChecklistsDone = (field) => CheckIfChecklistAreDone({ fields: [field], related_checklists: checklist.related_checklists })

		if (checklist.invert_order === 0) {
			for (let item of items) {
				if (!item.answer.value_id && !disabled_categories.includes(item.checklist_subtitle_id)) {
					to_highligh = { element: { ...item }, type: "item" }
					break
				}
			}
			if (!to_highligh) {
				for (let field of fields) {
					if (field.required && !field.value || !areChecklistsDone(field)) {
						const hasParent = !!field.checklist_field_option_id

						if (hasParent) {
							const flatten_options = fields.map(f => f.options).flat()
							const parent_option = flatten_options.find(op => op.id === field.checklist_field_option_id)
							const option_parent_field = fields.find(f => f.id === parent_option.checklist_field_id)

							// Verificar si el campo tiene un checklist relacionado requerido no completado
							if (option_parent_field.value === parent_option.label) {
								to_highligh = { element: { ...field }, type: "field" }
								break
							}
							else if (!areChecklistsDone(field)) {
								to_highligh = { element: { ...field }, type: "field" }
								break
							}
						} else {
							to_highligh = { element: { ...field }, type: "field" }
							break
						}
					}
				}
			}
		} else {
			for (let field of fields) {
				if (field.required && !field.value || !areChecklistsDone(field)) {
					const hasParent = !!field.checklist_field_option_id
					if (hasParent) {
						const flatten_options = fields.map(f => f.options).flat()
						const parent_option = flatten_options.find(op => op.id === field.checklist_field_option_id)
						const option_parent_field = fields.find(f => f.id === parent_option.checklist_field_id)

						if (option_parent_field.value === parent_option.label) {
							to_highligh = { element: { ...field }, type: "field" }
							break
						}
						else if (!areChecklistsDone(field)) {
							to_highligh = { element: { ...field }, type: "field" }
							break
						}
					} else {
						to_highligh = { element: { ...field }, type: "field" }
						break
					}
				}
			}
			if (!to_highligh) {
				for (let item of items) {
					if (!item.answer.value_id && !disabled_categories.includes(item.checklist_subtitle_id)) {
						to_highligh = { element: { ...item }, type: "item" }
						break
					}
				}
			}
		}

		if (!to_highligh && !ipaReady) {
			to_highligh = { element: null, type: "ipa" }
		}

		if (!to_highligh && !signsReady) {
			to_highligh = { element: null, type: "sign" }
		}

		setHighlighted(to_highligh)
	}


	function appendSave() {
		setSaveTimeout(s => s + 1)
	}

	function resolveSave() {
		setSaveTimeout(s => s - 1)
	}

	function showError() {
		setError(true)
		setSaveTimeout(0)
	}

	const itemsReady = useMemo(() => CheckIfItemsAreAnswered(checklist), [checklist])
	const fieldsReady = useMemo(() => CheckIfFieldsAreAnswered(checklist), [checklist])
	const signsReady = useMemo(() => CheckIfSignsAreReady(checklist), [checklist, highlighted])
	const ipaReady = useMemo(() => CheckIfIpaIsReady(checklist, ipa_percentage), [checklist, highlighted])
	const finished = useMemo(() => checklist.date_done !== "0000-00-00" || checklist.expired, [checklist])
	const expired = useMemo(() => checklist.expired, [checklist])
	const checklist_ready = itemsReady && fieldsReady && signsReady && ipaReady
	const hasPermissions = isAdmin || isResponsible
	const disabledEnd = !checklist_ready || !hasPermissions || saveTimeout > 0
	const rejected = useMemo(() => checklist.rejections?.length > 0 || !!checklist.rejection_motive, [checklist])
	const imResponsibleOfSection = useMemo(() => {
		const sections = checklist.categories || []
		const enabledUsers = sections.map(section => section.users).flat().filter(user => !user.disabled)
		return enabledUsers.some(user => String(user.user_id) === String(currentUser.id))
	})
	const authorized = useMemo(() => {
		return checklist.authorizations?.every(auth => auth.amount >= auth.signs?.length) || false
	}, [checklist])


	if (noChecklist)
		return (
			<Box sx={{ maxWidth: 800, margin: 3 }}>
				<Typography variant="h4">{t('checklistView.checklistNotFound')}</Typography>
				<Button
					sx={{ margin: '12px 0' }}
					color="info"
					variant="contained"
					onClick={() => history.goBack()}
				>
					{t('checklistView.goBack')}
				</Button>
			</Box>
		)

	if (loading)
		return (
			<Box sx={css.container}>
				<LoaderAnimator />
			</Box>
		)

	return (
		<Box sx={css.container}>
			<LoadingDialog open={dialogLoading} />
			<ChecklistContext.Provider
				value={{
					checklist,
					setChecklist: setAsyncChecklist,
					setMetadata,
					isAdmin,
					isResponsible,
					hasPermissions,
					finished,
					expired,
					setHighlighted,
					responsible,
					current_user: user,
					highlighted,
					appendSave,
					showError,
					resolveSave,
					setNoChecklist,
					rejecting,
					setRejecting,
					fieldsReady,
					itemsReady,
					signsReady,
					ipaReady,
					imResponsibleOfSection,
					setRejectionComment,
					rejectionComment
				}}
			>
				<Box sx={css.leftContainer}>
					<ConectionDisclaimer />
					<Box sx={css.mainContainer}>
						<SecondaryInfo />
						{online && hasPermissions && (
							<ChecklistStructure
								onSave={updateSubdivision}
								disabled={!hasPermissions}
								base_subdivision_id={checklist.base_subdivision_id}
							/>
						)}
						{checklist?.related_checklists?.length > 0 && <RelatedChecklistQuickAccess />}
						{hasPermissions && <ChecklistSupplies finished={finished} onEdit={editSupply} />}
						{rejected && !finished && (

							<Box sx={{ ...section_separation, ...paper_style }}>
								<Typography variant="h4" color="warning.main" sx={title_style}>
									{t('checklistView.rejectedChecklist')}
								</Typography>
								{checklist?.rejection_motive &&
									<>
										<Typography variant="h4" >{t('checklistView.rejectionReason')}</Typography>
										<Typography variant="subtitle1">{checklist.rejection_motive.split(";")[0] || t('checklistView.noMainRejectionMotive')}</Typography>
										<RejectionCommentField items={checklist.checklist_items2} motive={checklist.rejection_motive} fields={checklist.fields} editable={false} />
									</>
								}
								<Typography variant="caption">
									{t('checklistView.rejectedBy', {
										date: moment(checklist?.rejection_date).format('YYYY-MM-DD HH:mm'),
										user: checklist?.rejection_user_name,
									})}
								</Typography>
							</Box>

						)}
						<ContentSelector />
					</Box>
					{!finished && (
						<Box sx={end_flex_buttons}>
							<Box sx={css.end_button}>
								{disabledEnd ? (
									<Button
										sx={{ color: 'white' }}
										variant="contained"
										color="warning"
										onClick={searchAndHighlighPending}
										endIcon={<HelpOutlineIcon />}
									>
										{t('checklistView.viewMissingResponses')}
									</Button>
								) : (
									<Button color="primary" variant="contained" disabled={disabledEnd} onClick={onFinish}>
										{t('checklistView.finishChecklist')}
									</Button>
								)}
							</Box>
						</Box>
					)}
					{finished && (
						<Box>
							{readyForAnother && checklist.quick_access === 1 && (
								<Box sx={end_flex_buttons}>
									{online && (
										<Button color="primary" variant="contained" onClick={onRestart}>
											{t('checklistView.createAnother')}
										</Button>
									)}
								</Box>
							)}
							<Button
								sx={{ margin: '12px 0' }}
								color="info"
								variant="contained"
								onClick={() => history.goBack()}
								fullWidth
							>
								{t('checklistView.goBack')}
							</Button>
							<FinishedCard />
							<IfOnline>
								<Authorizations onSign={onSignAuthorization} onReject={onRejecChecklist} />
								<Comments />
								{authorized && <FinishNotification />}
							</IfOnline>
							<IfOffline>
								<Typography variant="subtitle2" sx={italic} color="warning.main">
									{t('checklistView.offlineWarning')}
								</Typography>
							</IfOffline>
						</Box>
					)}
				</Box>
				<LoadingCard loading={saveTimeout > 0} />
				<ErrorCard open={throwError} onClose={() => setError(false)} />
			</ChecklistContext.Provider>
		</Box>
	)
}

export default withRouter(ChecklistView)