import Resizer from 'react-image-file-resizer'
import { PDFDocument } from 'pdf-lib'
import QRCode from 'qrcode'
import { getCrits } from '../API/enterprise'
import moment from 'moment'
import { uploadFile } from '../API/users'
import { transformFindingFormToPlain } from '../Components/Findings/Card/ExtraFieldsDialog'
import chroma from 'chroma-js'
import CryptoJS from 'crypto-js'

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	// [::1] is the IPv6 localhost address.
	window.location.hostname === '[::1]' ||
	// 127.0.0.0/8 are considered localhost for IPv4.
	window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
)

export const transformToOptions = (arrayOfObjects) => {
	if (Array.isArray(arrayOfObjects)) {
		const result = arrayOfObjects.map((element) => {
			return { value: element.id, label: element.name, ...element }
		})
		return result
	}
	return []
}

export const transformToOptionsNames = (arrayOfObjects) => {
	if (Array.isArray(arrayOfObjects)) {
		const result = arrayOfObjects.map((element) => {
			return { value: element.name, label: element.name, ...element }
		})
		return result
	}
	return []
}

export function transformArrayToOptions(array) {
	if (!Array.isArray(array)) return []
	return array.map((element) => {
		return { value: element, label: element }
	})
}

export const getCellValue = (data, cell) => {
	let value = data[cell.name]
	const type = cell.type ? cell.type : false
	if (type && type === "select") {
		const elem_id = data[cell.name]
		const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
		value = option ? option.label : "No asignado..."
	}
	if (type && type === "select_create") {
		const elem_id = data[cell.name]
		const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
		value = option ? option.label : "No asignado..."
	}
	if (type && type === "multiselect") {
		const elem_ids = data[cell.name] || []
		value = elem_ids.map((elem_id) => {
			const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
			return option ? `${option.label} ` : "No asignado..."
		})
		value = value.length > 0 ? value : "No asignado..."
	}
	return value
}

export const getTableCellValue = (element, header) => {
	if (header.label === "all") {
		return element
	}

	const keys = header.label.split(".")

	return keys.reduce((acc, key) => {
		return acc && acc[key] !== undefined ? acc[key] : ""
	}, element)
};


export const transformToMakerDispatch = (dispatch, dispatchedFunc, type) => {
	return (...args) => {
		dispatch({ type: `REQUEST_${type}` })
		return dispatchedFunc(...args)
			.then(() => {
				dispatch({ type: `SUCCESS_${type}` })
			})
			.catch(() => {
				dispatch({ type: `ERROR_${type}` })
			})
	}
}

export const resizeImageAndGetFile = (file, callback) => {

	if (file === "remove") return callback(null, null)
	if (!file) return null

	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
			uri => {
				resolve(uri)
			},
			'base64'
		)
	})

	resizeFile(file).then(image => {
		fetch(image).then(res => res.blob())
			.then(blob => {
				const newFile = new File([blob], file.name.replace("png", "jpeg"), { type: "image/jpeg" })
				callback(image, newFile)
			})
	})
}

export const resizeImageGetFile = async (file) => {
	console.log(file)
	if (file === "remove") return [null, null]
	if (!file) return [null, null]

	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
			uri => {
				resolve(uri)
			},
			'base64'
		)
	})

	const image = await resizeFile(file)
	const res = await fetch(image)
	const blob = await res.blob()
	const newFile = new File([blob], file.name.replace("png", "jpeg"), { type: "image/jpeg" })
	console.log(newFile)
	return [image, newFile]
}

export async function resizeImage(file) {
	if (!file) return null
	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
			uri => {
				resolve(uri)
			},
			'base64'
		)
	})
	const image = await resizeFile(file)
	return image // URL of the image
}

export const mergeMultiplePdfFiles = async (files = [], fileName = "merged.pdf", byte_files = []) => {
	const mergedPDF = await PDFDocument.create()

	const bytes_promises = byte_files.map(async (file) => {
		const bytes = file

		let document1 = await PDFDocument.load(bytes)
		const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

		copiedPages.forEach(page => mergedPDF.addPage(page))
	})

	await Promise.all(bytes_promises)

	const promises = files.map(async (file) => {
		const pdfBytes = await fetch(file).then(response => response.arrayBuffer())
		const bytes = new Uint8Array(pdfBytes)

		let document1 = await PDFDocument.load(bytes)
		const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

		copiedPages.forEach(page => mergedPDF.addPage(page))
	})

	await Promise.all(promises)

	const resultInBytes = await mergedPDF.save()

	var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

	var link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = fileName
	link.click()
}

export const mergeMultiplePdfFilesAndGet = async (files = []) => {
	const mergedPDF = await PDFDocument.create()

	const promises = files.map(async (file) => {
		const pdfBytes = await fetch(file).then(response => response.arrayBuffer())
		const bytes = new Uint8Array(pdfBytes)

		let document1 = await PDFDocument.load(bytes)
		const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

		copiedPages.forEach(page => mergedPDF.addPage(page))
	})

	await Promise.all(promises)

	return mergedPDF
}

export const addQrToFile = async (pdfDoc, name, qrcode, toReturn = false) => {

	const pngImageBytes = await QRCode.toDataURL(qrcode)
	const pngImage = await pdfDoc.embedPng(pngImageBytes)

	const docPages = pdfDoc.getPages()
	docPages.forEach(page => {
		page.drawImage(pngImage, {
			x: 6,
			y: 9,
			height: 60,
			width: 60
		})
		page.drawText(`Este documento fue válidamente firmado usando Tazki`, {
			x: 6,
			y: 3,
			size: 6,
		})
	})

	const resultInBytes = await pdfDoc.save()

	if (toReturn) return resultInBytes

	var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

	var link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = name
	link.click()
}


export function jsonToFormData(data) {
	const form = new FormData()
	Object.keys(data).forEach(key => {
		if (data[key] !== null) { form.append(key, data[key]) }
	})
	return form
}

export const saveUserTypeFilter = (filter) => {
	localStorage.setItem("user_filter", filter)
}

export const getUserTypeFilter = () => {
	const filter = localStorage.getItem("user_filter") || "Todos"
	return filter
}

export function fetchFunction(endpoint, setter, body) {
	return async () => {
		const response = await endpoint(body)
		setter(response.data.info)
	}
}

export function completePath(file, url) {
	if (!file) return "/noimage.png"
	if (file.includes("blob:")) return file
	if (file.includes("amazonaws.com")) return file
	if (file.includes("data:image")) return file
	let url_final = url
	if (url.charAt(url.length - 1) === '/') {
		url_final = url.slice(0, -1)
	}
	let encoded_file = encodeURIComponent(file)
	return `${url_final}/${encoded_file}`
}

export function createDataFromJson(json) {
	const body = new FormData()
	Object.keys(json).forEach(key => {
		if (Array.isArray(json[key])) {
			json[key].forEach(value => {
				body.append(`${key}[]`, value === null ? "" : value)
			})
		} else {
			body.append(key, json[key] === null ? "" : json[key])
		}
	})
	return body
}

export async function fromBase64ToFile(base64) {
	// Check if its only 1 file
	if (typeof base64 === "string") {
		const files = base64.split("&#&")
		if (files.length > 1) return null
	}

	if (Array.isArray(base64)) {
		const files = []
		await Promise.all(base64.map(async url => {
			if (url.includes("amazonaws")) return files.push(url)
			const response = await fetch(url)
			const contentType = url.substring(5, url.indexOf(';'))
			const extension = contentType.split("/")[1]
			const blob = await response.blob()
			const newFile = new File([blob], extension, { type: contentType })
			files.push(newFile)
		}))
		return files
	}

	const file_name = base64.split("|&|")[1] || ""
	const base64_string = base64.split("|&|")[0]
	const response = await fetch(base64_string)
	const contentType = base64_string.substring(5, base64_string.indexOf(';'))
	const extension = contentType.split("/")[1]
	const blob = await response.blob()
	let newFile = new File([blob], file_name + "." + extension, { type: contentType })

	return newFile
}

export async function fromBase64ToUploadedFile(base64) {
	// Split string into array of strings
	const files = base64.split("&#&");
	const uploadPromises = [];

	for (const file of files) {
		// Check if file is already uploaded
		if (file.includes("amazonaws")) {
			uploadPromises.push(Promise.resolve(file));
			continue;
		}

		// Create a promise to upload file otherwise
		const uploadPromise = fromBase64ToFile(file).then(new_file => {
			const file_body = new FormData();
			file_body.append("file", new_file);
			file_body.append("name", new_file.name);
			return uploadFile(file_body).then(response => response.data.info);
		});

		uploadPromises.push(uploadPromise);
	}

	// Wait for all upload promises to resolve
	const uploadedFiles = await Promise.all(uploadPromises);

	const uploaded_files_string = uploadedFiles.join("&#&");
	return uploaded_files_string;
}

export async function fileToBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onloadend = () => {
			// The result attribute contains the data as a Base64 encoded string
			const base64String = reader.result
			// Resolve the promise with the Base64 string
			resolve(base64String)
		}
		reader.onerror = reject // Reject the promise in case of an error
		// Read the file as a data URL, which results in a Base64 string
		reader.readAsDataURL(file)
	})
}


export async function transformOfflineRequestToOnline(body) {
	const new_body = { ...body }
	const values = Object.values(new_body)
	const hasBase64Strings = values.some(v => String(v).includes("data:"))
	if (!hasBase64Strings) return new_body
	for (const key in new_body) {
		const isBase64String = String(new_body[key]).includes("data:")
		if (isBase64String) {
			new_body[key] = await fromBase64ToFile(new_body[key])
		}
	}
	return createDataFromJson(new_body)
}

export async function transformOfflineRequestToOnlinePlain(body) {
	const new_body = { ...body }

	// Remove any "form" key for data consistency
	if (new_body.form) delete new_body.form

	const values = Object.values(new_body)
	const hasBase64Strings = values.some(v => String(v).includes("data:"))
	if (!hasBase64Strings) return new_body
	for (const key in new_body) {
		const isBase64String = String(new_body[key]).includes("data:")
		if (isBase64String) {
			new_body[key] = await fromBase64ToUploadedFile(new_body[key])
		}
	}
	return new_body
}

export async function transformOfflineFormRequestToOnlinePlain(body) {
	const new_body = { ...body }
	let new_form = new_body.form

	// Run the following only if form is in the body

	if (!!new_form) {
		// If form is a JSON string, parse it
		if (typeof new_form === "string") {
			new_form = JSON.parse(new_form)
		}

		console.log(new_form)

		const new_form_plain = await transformFindingFormToPlain(new_form)
		new_body.form = JSON.stringify(new_form_plain)
	}

	const values = Object.values(new_body)
	const hasBase64Strings = values.some(v => String(v).includes("data:"))
	if (!hasBase64Strings) return new_body
	for (const key in new_body) {
		const isBase64String = String(new_body[key]).includes("data:")
		if (isBase64String) {
			new_body[key] = await fromBase64ToUploadedFile(new_body[key])
		}
	}
	return new_body
}

export function splitArrayIntoBatches(array, batchSize) {
	const batches = []
	for (let i = 0; i < array.length; i += batchSize) {
		batches.push(array.slice(i, i + batchSize))
	}
	return batches
}

export async function updateLocalCrits() {
	// TODO check before if there is a user logged in to avoid unnecessary requests
	try {
		const response = await getCrits()
		if (response.data.status === "success") {
			localStorage.setItem("crits", JSON.stringify(response.data.info))
			localStorage.setItem("crits_last_update", moment().format("YYYY-MM-DD"))
		}
		else return localStorage.removeItem("crits")
	} catch {
		console.log("Not logged in yet")
	}
}

export function getLocalCrits() {
	const stringCrits = localStorage.getItem("crits")
	const lastUpdate = localStorage.getItem("crits_last_update")
	if (!stringCrits) { // If there are no crits in local storage
		updateLocalCrits()
		return []
	}
	if (!lastUpdate || moment(lastUpdate).isBefore(moment().subtract(1, "day"))) { // If last update was more than 1 day ago
		updateLocalCrits()
	}
	return JSON.parse(stringCrits)
}

export function getBusinessHours() {
	const enterpriseData = localStorage.getItem("enterprise")
	if (!isValidJson(enterpriseData)) return false
	const enterprise = JSON.parse(enterpriseData)
	if (!enterprise) return false
	return enterprise.business_hours
}

export const initialDates = {
	date_start: moment().startOf("week").format("YYYY-MM-DD"),
	date_end: moment().endOf("week").format("YYYY-MM-DD")
}

export const initialDatesPeriod = (format = "week") => ({
	date_start: moment().startOf(format).format("YYYY-MM-DD"),
	date_end: moment().endOf(format).format("YYYY-MM-DD")
})

export const formatDateTime = value => {
	return moment(value).format("YYYY-MM-DD HH:mm")
}

export const formatReadable = value => {
	return moment(value).format("DD [de] MMM [del] YYYY")
}

export const addRemoveInArray = (arr, element) => {
	arr = arr || []
	if (arr.includes(element)) return arr.splice(arr.indexOf(element), 1)
	return arr.push(element)
}

/**
 * Adds or removes an element in an array of objects based on the element's id.
 * If the element is not found in the array, it will be added.
 * If the element is found in the array, it will be removed.
 *
 * @param {Array} arr - The array of objects.
 * @param {Object} element - The element to add or remove.
 * @returns {number} - The new length of the array after adding or removing the element.
 */
export function addREmoveInArrayOfObjects(arr, element) {
	const index = arr.findIndex(e => e.id === element.id)
	if (index === -1) return arr.push(element)
	return arr.splice(index, 1)
}

export const getSelectedFilters = () => {
	const filters = localStorage.getItem("filters")
	if (!filters) return []
	return JSON.parse(filters)
}

export const getFileNameFromPath = path => {
	if (!path) return ""
	const lastSlash = path.lastIndexOf("/")
	return path.substring(lastSlash + 1)
}

export async function fetchWithProgress(requests, progressCallback, action) {
	let completedRequests = 0
	const promises = requests.map(async (request, index) => {
		const response = await action(request)
		completedRequests++
		progressCallback(completedRequests) // Notify the progress
		return response
	})

	return Promise.all(promises)
}

export const debug_log = (message) => {
	if (isLocalhost) return console.log(message)
	return null
}

export function deepClone(obj) {
	if (obj === null || typeof obj !== 'object') {
		return obj
	}

	const clone = Array.isArray(obj) ? [] : {}

	for (let key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			clone[key] = deepClone(obj[key])
		}
	}

	return clone
}

export function addNameToB64(value, name) {
	return value + "|&|" + name
}

export function transformTableDataToPivotData(headers, body) {
	console.log({ headers })
	const cleanHeaders = headers.filter(header => !header.render)
	const headersArray = cleanHeaders.map(header => header.name)
	const bodyArrays = body.map(row => {
		const newRow = []
		cleanHeaders.forEach(header => {
			let element_value = getTableCellValue(row, header)
			if (header.format) { element_value = header.format(element_value, row) }
			newRow.push(element_value)
		})
		return newRow
	})

	return [headersArray, ...bodyArrays]
}

export function appendFindingFormsToTableInfo(tableInfo, data) {
	const forms = data.map(d => d.form).filter(Boolean)
	const parsedForms = forms.map(JSON.parse)
	const formKeys = parsedForms.map(form => form.map(question => question.label)).flat().filter(Boolean)
	const uniqueFormKeys = [...new Set(formKeys)]
	const newHeaders = tableInfo.concat(uniqueFormKeys.map(key => ({ name: key, label: key })))
	return newHeaders
}

export function appendFindingFormValuesToTableData(data) {
	const finalData = []
	for (const row of data) {
		const result = { ...row }
		const form = JSON.parse(row.form)
		if (!form) {
			finalData.push(result)
			continue
		}
		for (const question of form) {
			if (!!result[question.label]) continue // If the value already exists, skip

			// Add the question value to the result object
			result[question.label] = question.value
		}
		finalData.push(result)
	}
	return finalData
}

function randomRGBColor() {
	const r = Math.floor(Math.random() * 256)
	const g = Math.floor(Math.random() * 256)
	const b = Math.floor(Math.random() * 256)
	return `rgb(${r},${g},${b})`
}

export function generateColors(amount) {
	const colors = []
	for (let i = 0; i < amount; i++) {
		const color = randomRGBColor()
		colors.push(color)
	}
	return colors
}

export function generateDistinctColors(baseColor, numberOfColors) {
	const colors = [];
	const baseHue = chroma(baseColor).get('hsl.h') || 0; // Default to 0 if undefined
	const saturation = 0.65; // Standardized saturation
	const lightness = 0.55;  // Standardized lightness

	for (let i = 0; i < numberOfColors; i++) {
		const hueOffset = (i * 360) / numberOfColors;
		const hue = (baseHue + hueOffset) % 360;
		const color = chroma.hsl(hue, saturation, lightness).hex();
		colors.push(color);
	}

	return colors;
}

/**
 * Downloads a file from a given callback function and saves it with the given name.
 * @param {Function} callback - The callback function that returns the file data.
 * @param {Object} body - The request body to be passed to the callback function.
 * @param {string} name - The name of the file to be downloaded.
 * @returns {Promise<void>} - A Promise that resolves when the file has been downloaded.
 */
export async function downloadFromMethod(callback, body, name) {
	const response = await callback(body)
	const url = URL.createObjectURL(new Blob([response.data]))
	const a = document.createElement('a')
	a.href = url
	a.download = name
	a.click()
	URL.revokeObjectURL(url)
}


/**
 * Calls a callback function with a given body and returns a blob object.
 * @param {Function} callback - The callback function to call.
 * @param {Object} body - The body to pass to the callback function.
 * @param {string} name - The name of the blob object.
 * @returns {Blob} - A blob object with the response data and type "application/pdf".
 */
export async function getBlobFromMethod(callback, body) {
	const response = await callback(body) // The response type is a blob
	const blob = new Blob([response.data], { type: "application/pdf" })
	return blob
}

export async function fromBlobToUint8Array(blob) {
	const arrayBuffer = await blob.arrayBuffer()
	const uint8Array = new Uint8Array(arrayBuffer)
	return uint8Array
}

export async function transformToUint8Array(element) {
	// Element could be a blob or a Uint8Array
	if (element instanceof Uint8Array) return element
	return await fromBlobToUint8Array(element)
}

/**
 * Returns a function that compares the ID of an element with a given ID.
 * @param {string|number} comparedId - The ID to compare with.
 * @returns {function} - The comparison function.
 */
export function compareByStrings(comparedId) {
	return function (element) {
		return String(element?.id) === String(comparedId)
	}
}


export function formatDuration(minutes) {
	if (!minutes) return "No definida"

	const hours = Math.floor(minutes / 60)
	const remainingMinutes = minutes % 60
	if (hours > 0) {
		if (remainingMinutes > 0) {
			return `${hours}h ${remainingMinutes}min`
		} else {
			return `${hours}h`
		}
	} else {
		return `${remainingMinutes}min`
	}
}

export function stopPropagation(event) {
	event.stopPropagation()
}

export function isValidParameter(parameter) {
	return parameter != null && parameter !== ""
}

export function removeAccents(str) {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function mergeArrays(arr1, arr2, keys = ['key']) {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) return [];
	if (!Array.isArray(keys)) keys = [keys];
	const map = new Map();

	arr1.forEach(item => {
		const key = keys.map(k => item[k]).join('-');
		const hash = hashString(key);
		map.set(hash, item);
	})
	arr2.forEach(item => {
		const key = keys.map(k => item[k]).join('-');
		const hash = hashString(key);
		map.set(hash, { ...map.get(hash), ...item });
	})

	return Array.from(map.values());
}

function hashString(message) {
	const hash = CryptoJS.SHA256(message)
	return hash.toString(CryptoJS.enc.Hex)
}

export function isValidJson(value) {
	try {
		JSON.parse(value)
		return true
	} catch {
		return false
	}
}

export function replaceTranslationVariables(text, variableNames) {
	const regex = /%([^%]+)%/g;
	let result = [];
	let lastIndex = 0;
	let match;

	while ((match = regex.exec(text)) !== null) {
		// Add the text before the match
		if (match.index > lastIndex) {
			result.push(text.slice(lastIndex, match.index));
		}
		// Add the variable part wrapped in a span
		const variableName = match[1]; // Extract the variable name without '%'
		const variableValue = variableNames[variableName];
		result.push(variableValue);
		lastIndex = regex.lastIndex;
	}

	// Add any remaining text after the last match
	if (lastIndex < text.length) {
		result.push(text.slice(lastIndex));
	}

	return result;
}

/// HORARIOS HABILES POR DEFAULT
const WORKING_HOURS_START = 8 // 8:00 am
const WORKING_HOURS_END = 18 // 18:00 pm
const WORKING_DAYS = [1, 2, 3, 4, 5] // Lunes a Viernes 

/**
 * 
 * @param {Object} startDate 
 * @param {number} hoursOffset 
 * @returns {Object} 
 */


// Bucle para sumar las horas restantes
export function calculateBusinessHoursDeadline(startDate, hoursOffset) {
	let remainingHours = hoursOffset
	let deadline = moment(startDate)
	const startMinute = deadline.minute()

	while (remainingHours > 0) {
		let nextHour = deadline.clone().add(1, 'hour').minute(startMinute)

		const isWorkingDay = WORKING_DAYS.includes(nextHour.day())
		const isWorkingHour = nextHour.hour() >= WORKING_HOURS_START && nextHour.hour() < WORKING_HOURS_END

		if (isWorkingDay && isWorkingHour) {
			remainingHours--
			deadline = nextHour
		} else {

			if (!isWorkingDay || nextHour.hour() >= WORKING_HOURS_END) {
				remainingHours--
				deadline.add(1, 'day').hour(WORKING_HOURS_START).minute(startMinute).second(0)
				// Saltar al próximo día laboral si es fin de semana
				while (!WORKING_DAYS.includes(deadline.day())) {
					deadline.add(1, 'day')
				}
			} else if (nextHour.hour() < WORKING_HOURS_START) {
				deadline.hour(WORKING_HOURS_START).minute(startMinute).second(0)
			}
		}
	}
	return deadline.format("YYYY-MM-DD HH:mm")
}

export function getViewType() {
	const path = window.location.pathname
	const parts = path.split('/')
	const viewType = parts.find(part => part === 'talks' || part === 'trainings')
	return viewType || null
}

export function transformToExtraOptions(options) {
	if (!options) return []
	return options.map(option => {
		if (typeof option === "string") {
			return {
				label: option.split('&')[0],
				value: option.split('&')[0]
			}
		}
		return { label: option?.label || "", value: option?.label || "" }
	})
}

export function clickWrapper(action, row) {
	return (event) => {
		event.stopPropagation()
		if (action.link) {
			if (event.ctrlKey) {
				return window.open(action.link(row))
			}
			if (event.button === 1) {
				return window.open(action.link(row))
			}
		}
		action.action(row)()
	}
}

export function debounce(func, delay) {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => func.apply(this, args), delay);
	};
}

export function isAxiosError(axiosObject) {
	console.log(axiosObject?.name)
	console.log(axiosObject?.response)
	return axiosObject?.name === "AxiosError" && axiosObject?.response?.data?.status === "error"
}