import { Box, Button, Typography } from '@mui/material'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import { inline_space, labeless, title_style } from '../../../../Utils/defaultStyles'
import { useContext } from 'react'
import { FindingDialogContext } from '../FindingDialog'
import moment from 'moment'
import { calculateBusinessHoursDeadline } from '../../../../Utils/functions'
import { useTranslation } from 'react-i18next'

function DescriptionStep() {

	const { params, setParams, finding_actions, transformBaseToOptions, nextStep, prevStep, onCancel, finding_types, finding_timings, businessHoursOnly } = useContext(FindingDialogContext)
	const { t } = useTranslation("findingCreationDialog")

	function onChange(event) {
		const { target } = event
		const { name, value } = target
		const new_params = { ...params, [name]: value }
		if (name === 'base_finding_action_id') {
			const action = finding_actions.find(a => a.id === value)
			new_params.free_description = action?.content

			// Set criticality based on measure
			new_params.free_criticality = action?.default_criticality

			// Set date_limit based on criticality
			const timing = finding_timings.find(t => t.finding_type_id === params.finding_type_id && t.default_criticality === action?.default_criticality)

			console.log({ params, timing, action })

			if (businessHoursOnly)
				new_params.date_limit = calculateBusinessHoursDeadline(moment(), timing?.offset_hours)
			else new_params.date_limit = moment().add(timing?.offset_hours, 'hours').format('YYYY-MM-DD HH:mm')
			new_params.maxDate = !!timing?.offset_hours ? (businessHoursOnly ? calculateBusinessHoursDeadline(moment(), timing?.offset_hours) :
				moment().add(timing?.offset_hours, 'hours').format('YYYY-MM-DD HH:mm')) : null
		}
		setParams(new_params)
	}

	if (finding_types.length > 0 && !params.finding_type_id) {
		prevStep()
	}

	const stepReady = !!params.free_description

	return (
		<Box>
			<Typography variant='h4' sx={title_style} color="info.main" >{t('data.breachDescription')}</Typography>
			{!!finding_actions.length &&
				<Box>
					<Typography variant='subtitle2' sx={labeless} >{t('messages.selectDescription')}</Typography>
					<SelectInput options={transformBaseToOptions(finding_actions)} label="" name="base_finding_action_id" value={params.base_finding_action_id} onChange={onChange} />
				</Box>
			}
			<Box>
				<Typography variant='subtitle2' sx={labeless} >{t('data.description')}</Typography>
				<TextInput label="" name="free_description" value={params.free_description} onChange={onChange} />
			</Box>
			<Box sx={inline_space} >
				{!!finding_types.length &&
					<Button variant="outlined" onClick={prevStep}>
						{t('stepBackwards')}
					</Button>
				}
				{!finding_types.length &&
					<Button variant="contained" color="error" onClick={onCancel}>
						{t('actions.cancel')}
					</Button>
				}
				<Button variant="contained" color="success" onClick={nextStep} disabled={!stepReady}>
					{t('actions.continue')}
				</Button>
			</Box>
		</Box>
	)
}

export default DescriptionStep