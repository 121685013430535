import { KeyboardArrowDown } from '@material-ui/icons'
import { Box, InputAdornment, TextField, } from '@mui/material'
import { useContext, } from 'react'
import AppContext from '../../AppContext'
import { UserSelectContext } from '../Dialogs/UserSelectDialog'
import newTheme from '../../newTheme'

const css = {
	input: {
		margin: '12px 0'
	}
}

function UsersInput({
	value,
	onChange,
	label,
	name,
	isMultiple,
	inputStyle,
	options,
	branch_id: branch_id_prop,
	onBlur,
	disabled,
	limit = []
}) {


	const { openUsersDialog, getUsersNames } = useContext(UserSelectContext)
	const { branch_id } = useContext(AppContext)

	const is_multiple = isMultiple || options === "multiple"

	function onOpenDialog() {

		// value can be a single user id, an array of user ids or null
		let selected_users = []
		if (!!value) {
			selected_users = is_multiple ? value : [value]
		}
		function callback(new_selected_users) {
			// new_selected_users is an array of user objects
			// if the input is multiple it should return an array of user ids
			// if the input is not multiple it should return a single user id

			let final_value = null
			if (is_multiple) { final_value = new_selected_users.map(user => String(user.id)) }
			else { final_value = !!new_selected_users?.[0]?.id ? String(new_selected_users[0].id) : null }
			onChange({ target: { value: final_value, name: name } })
			onBlur && onBlur({ target: { value: final_value, name: name } })
		}
		// Parameters: callback = null, selected_users = [], branch_id = null, multiple = true
		const real_branch_id = !!branch_id_prop ? branch_id_prop : branch_id
		openUsersDialog(callback, selected_users, String(real_branch_id), is_multiple, limit)
	}

	return (
		<Box sx={css.input}>
			<TextField
				value={!!value ? getUsersNames([value]) : ""}
				fullWidth
				name={name}
				variant="outlined"
				displayEmpty
				label={label}
				disabled={disabled}
				className={inputStyle}
				onClick={!disabled ? onOpenDialog : null}
				InputProps={{
					sx: {
						cursor: "pointer"
					},
					endAdornment: (
						<InputAdornment position="end">
							<KeyboardArrowDown />
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		</Box>
	)
}

export default UsersInput