import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import TextInput from "../../../../Shared/Inputs/TextInput"
import { getTotalCounters } from "../../Show/checklistUtils"
import { useTranslation } from "react-i18next"

function IDSField({ field, onChange, value, onBlur }) {
	const { finished, checklist, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	if (finished || !hasPermissions)
		return (
			<Box>
				<Typography variant="subtitle1">
					{t("ids.workerCount")}: {value}
				</Typography>
				<Typography variant="subtitle1">
					{t("ids.weightedEvents")}: {getTotalCounters(checklist)}
				</Typography>
				<Typography variant="subtitle1" style={{ marginBottom: 12 }}>
					{t("ids.result")}:
					{field?.value
						? ((getTotalCounters(checklist) * 100) / field?.value).toFixed(2)
						: 0}
				</Typography>
			</Box>
		)

	return (
		<Box style={{ paddingTop: 8 }}>
			<TextInput
				onBlur={onBlur}
				value={value}
				onChange={onChange}
				label={t("ids.workerCount")}
			/>
			<Typography variant="subtitle1">
				{t("ids.weightedEvents")}: {getTotalCounters(checklist)}
			</Typography>
			<Typography variant="subtitle1" style={{ marginBottom: 12 }}>
				{t("ids.result")}:
				{field?.value
					? ((getTotalCounters(checklist) * 100) / field?.value).toFixed(2)
					: 0}
			</Typography>
		</Box>
	)
}

export default IDSField
