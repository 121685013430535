import { Box, Paper, Typography, Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useMemo } from 'react'
import { PersonAdd } from '@material-ui/icons'
import { paper_style, text_space, end_buttons, labeless, inline_space_no_wrap } from '../../../Utils/defaultStyles'
import { ChecklistContext } from './ChecklistView'
import TextInput from '../../../Shared/Inputs/TextInput'
import { UserSelectContext } from '../../../Shared/Dialogs/UserSelectDialog'
import AppContext from '../../../AppContext'
import { editChecklist } from '../../../API/new_checklists'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import './animations.css'
import { evaluateIpaColor, parseIpaRules } from './checklistUtils'

const css = {
	header: {
		...text_space,
		...inline_space_no_wrap,
		gap: 8,
		marginBottom: 2,
	},
	users: {
		marginTop: 3,
		maxHeight: "300px",
		overflow: 'auto'
	},
	userGrid: {
		marginTop: 2,
		padding: 2,
		background: 'whitesmoke',
		borderRadius: 2,
	}
}

function ChecklistIPA() {
	const { checklist, hasPermissions, setChecklist, highlighted, finished } = useContext(ChecklistContext)
	const { openUsersDialog } = useContext(UserSelectContext)
	const { branch_id, enterprise } = useContext(AppContext)
	const [params, setParams, updateParams] = useChangeParams({ ipa_users: [] })
	const { t } = useTranslation('checklistShow')
	const ipaRules = useMemo(() => parseIpaRules(checklist?.ipa_color_rules, finished), [checklist?.ipa_color_rules])
	const ipaColor = useMemo(() => evaluateIpaColor(checklist?.ipa, ipaRules, finished), [checklist?.ipa, ipaRules])

	useEffect(() => {
		updateParams(checklist)
	}, [checklist])

	function selectUserHandler() {
		const callback = async (selectedUsers) => {
			const existingUsers = params.ipa_users
			const newUsers = [
				...selectedUsers.map((user) => ({
					id: user.id,
					name: user.name,
					users_amount: existingUsers.find((u) => u.id === user.id)?.users_amount || 0,
					contractors_amount: existingUsers.find((u) => u.id === user.id)?.contractors_amount || 0,
					ipa: existingUsers.find((u) => u.id === user.id)?.ipa || 0,
				})),
			]
			updateParams({ ...params, ipa_users: newUsers })
			await onSave(newUsers)
		}
		openUsersDialog(callback, checklist?.ipa_users?.map((u) => u.id) || [], branch_id, true)
	}

	function handleUserAmountChange(userId, value, field_type = "users_amount") {
		const updatedUsers = params.ipa_users.map((user) =>
			user.id === userId ? { ...user, [field_type]: value } : user
		)
		updateParams({ ...params, ipa_users: updatedUsers })
	}

	async function onSave(updatedUsers) {
		const body = { ...params, ipa_users: updatedUsers }
		const response = await editChecklist(body)
		setChecklist(response.data.info)
	}

	return (
		<Paper sx={{ ...paper_style, margin: '12px 0' }}>
			<Typography variant="h4" color="info.main">
				{enterprise.ipa_name}
			</Typography>

			<Box sx={css.header}>
				<Box sx={{ flexGrow: 1 }} className={highlighted ? "highlight_field highlight_background" : ""}>
					<Typography sx={labeless} variant="subtitle2">
						{t('secondaryInfo.workersCount')}
					</Typography>
					<TextInput
						type="number"
						label=""
						value={params?.users_amount}
						disabled={!hasPermissions || finished}
						name="users_amount"
						onChange={setParams}
						onBlur={() => onSave(params.ipa_users)}
						fullWidth
					/>
				</Box>
				<Typography variant="h4" sx={{ whiteSpace: 'nowrap', mr: 4 }} color={ipaColor}>
					{t('secondaryInfo.ipaValue', { ipa: enterprise.ipa_name })} {checklist?.ipa}
				</Typography>
			</Box>
			{params?.ipa_users?.length > 0 &&
				<Box sx={css.users}>
					<Grid container sx={css.userGrid}>
						<Grid item xs={3}>
							<Typography variant="subtitle2">
								{t('secondaryInfo.userName')}
							</Typography>
						</Grid>
						<Grid item xs={3} textAlign="center">
							<Typography variant="subtitle2">
								{t('secondaryInfo.workersCount')}
							</Typography>
						</Grid>
						<Grid item xs={3} textAlign="center">
							<Typography variant="subtitle2">
								{t('secondaryInfo.contractorsCount')}
							</Typography>
						</Grid>
						<Grid item xs={3} textAlign="center">
							<Typography variant="subtitle2">
								{t('secondaryInfo.ipaValue', { ipa: enterprise.ipa_name })}
							</Typography>
						</Grid>

						{params.ipa_users.map((user) => (
							<Grid container item xs={12} spacing={2} key={user.id} alignItems="center">
								<Grid item xs={3}>
									<Typography variant="subtitle1">{user.name}</Typography>
								</Grid>
								<Grid item xs={3}>
									<TextInput
										type="number"
										label=""
										value={user.users_amount}
										disabled={!hasPermissions || finished}
										onChange={(e) => handleUserAmountChange(user.id, e.target.value, "users_amount")}
										onBlur={() => onSave(params.ipa_users)}
										fullWidth
									/>
								</Grid>
								<Grid item xs={3}>
									<TextInput
										type="number"
										label=""
										value={user.contractors_amount}
										disabled={!hasPermissions || finished}
										onChange={(e) => handleUserAmountChange(user.id, e.target.value, "contractors_amount")}
										onBlur={() => onSave(params.ipa_users)}
										fullWidth
									/>
								</Grid>
								<Grid item xs={3} textAlign="center">
									<Typography variant="subtitle1">{user.ipa}</Typography>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Box>
			}
			{!finished &&
				<Box sx={{ ...end_buttons, marginTop: 2 }}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<PersonAdd />}
						onClick={selectUserHandler}
					>
						{t('secondaryInfo.selectUsers')}
					</Button>
				</Box>
			}
		</Paper>
	)
}

export default ChecklistIPA
