import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import TextField from "@mui/material/TextField"
import { inline_buttons } from "../../Utils/defaultStyles"
import SearchIcon from '@mui/icons-material/Search'
import { removeAccents } from "../../Utils/functions"
import { e } from "mathjs"
import { useDebounceFunctionCallback } from "../../Hooks/DebounceHook"

function SearchField({ icon = false, onEnterKey, onChange, label, name, value, fullWidth = false }) {

	const [search, setSearch] = useState("")
	const [debouncing, setDebouncing] = useState(false)

	const debounceFunction = useDebounceFunctionCallback(500)

	useEffect(() => {
		if (value !== search) { setSearch(value) }
	}, [value])

	function handleKeyDown(event) {
		event.stopPropagation()
		if (onEnterKey) {
			if (event.key === "Enter" && !!search && search.trim() !== "") {
				if (debouncing) return
				onEnterKey(search)
			}
		}
	}

	function handleChange(event) {
		const { target } = event
		const { value } = target
		setSearch(value)
		setDebouncing(true)
		function callback() {
			if (onChange) onChange(value)
			setDebouncing(false)
		}
		debounceFunction(callback)
	}

	return (
		<Box>
			<TextField
				variant="standard"
				label={label || ""}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				name={name}
				InputProps={{
					startAdornment: icon && <SearchIcon />
				}}
				value={search}
				autoFocus
				autoComplete="off"
				fullWidth={fullWidth}
			/>
		</Box>
	)
}

export default SearchField
