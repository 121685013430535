import { Menu, MenuItem, Box, Typography } from "@mui/material"
import { ErrorOutline } from '@mui/icons-material'
import newTheme from "../../../../newTheme"

const css = {
	crit: {
		width: 130,
		borderRadius: 2,
		border: '1px solid black',
		transition: 'all 0.2s ease-in-out',
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(1.05)'
		},
		padding: 2,
		margin: 2
	}
}


function CritsMenu({ crits, openCrits, toggleCrits, critsMenu, setParams, params, onSave }) {

	function onChangeCrit(value) {
		toggleCrits()
		setParams({ ...params, free_criticality: value || 0 })
		if (onSave) onSave(null, value)
	}

	return (
		<Menu open={openCrits} onClose={toggleCrits} anchorEl={critsMenu.current}>
			{crits.map((crit, index) => {
				const style = { ...css.crit }
				const text_style = {}
				style.border = `1px solid ${crit.color}`
				const selected = crit.value === params.free_criticality
				const canContrast = ![1, 2].includes(crit.value)
				if (selected) {
					style.transform = 'scale(1.05)'
					style.border = `1px solid black`
					style.backgroundColor = crit.color
					if (canContrast) text_style.color = 'white'
					if (!canContrast) text_style.color = newTheme.palette.primary.main
				}
				return (
					<MenuItem sx={style} onClick={() => onChangeCrit(crit.value)} key={index}>
						<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
							<ErrorOutline style={{ color: crit.color, ...text_style }} />
							<Typography variant='subtitle2' color={selected && canContrast ? "white.main" : "primary"} >{crit.label}</Typography>
						</Box>
					</MenuItem>
				)
			})}
		</Menu>
	)
}

export default CritsMenu