import instance2 from "../Utils/instance2"

export function getBaseFindingActions(body) {
	return instance2.get('base_finding_actions', { params: body })
}

export function createBaseFindingAction(body) {
	return instance2.post('base_finding_actions', body)
}

export function bulkCreateBaseFindingAction(body) {
	return instance2.post('base_finding_actions/bulk_create', body)
}

export function editBaseFindingAction(body) {
	return instance2.put(`base_finding_actions/${body.id}`, body)
}

export function deleteBaseFindingAction(body) {
	return instance2.delete(`base_finding_actions/${body.id}`, { data: body })
}
