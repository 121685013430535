import { CheckCircle, DateRange, Event, FormatAlignCenter, FormatListBulleted, FormatListNumbered, HelpOutline, ImageOutlined, LooksOneOutlined, PersonPin, TextFormat, DescriptionOutlined } from '@material-ui/icons'
import { CalculateOutlined, Engineering, Group, LocationOnOutlined } from '@mui/icons-material'
import moment from 'moment'
import { userTypes } from '../../../../Workers/Profile/info'

export function fieldTypes(t) {
	return (
		[
			{ name: t("drawer.types.text"), value: "text", icon: TextFormat },
			{ name: t("drawer.types.selectOption"), value: "select", icon: FormatListBulleted },
			{ name: t("drawer.types.multiSelect"), value: "multiselect", icon: FormatListNumbered },
			{ name: t("drawer.types.image"), value: "img", icon: ImageOutlined },
			{ name: t("drawer.types.date"), value: "date", icon: Event },
			{ name: t("drawer.types.number"), value: "number", icon: LooksOneOutlined },
			{ name: t("drawer.types.richText"), value: "rte", icon: FormatAlignCenter },
			{ name: t("drawer.types.rut"), value: "rut", icon: TextFormat },
			{ name: t("drawer.types.duration"), value: "duration", icon: DateRange },
			{ name: t("drawer.types.compliance"), value: "compliance", icon: CheckCircle },
			{ name: t("drawer.types.ids"), value: "ids", icon: HelpOutline },
			{ name: t("drawer.types.location"), value: "location", icon: LocationOnOutlined },
			{ name: t("drawer.types.user"), value: "users", icon: PersonPin },
			{ name: t("drawer.types.multiUsers"), value: "multi_users", icon: Group },
			{ name: t("drawer.types.contractor"), value: "contractor", icon: Engineering },
			{ name: t("drawer.types.multiContractors"), value: "multi_contractors", icon: Engineering },
			{ name: t("drawer.types.calculation"), value: "calc", icon: CalculateOutlined },
			{ name: t("drawer.types.document"), value: "document", icon: DescriptionOutlined },
		]
	)
}

export const userInfoFields = [
	{ label: "email", nameKey: "userInfo.email", valueKey: "email" },
	{ label: "rut", nameKey: "userInfo.rut", valueKey: "rut" },
	{ label: "phone", nameKey: "userInfo.phone", valueKey: "phone" },
	{ label: "address", nameKey: "userInfo.address", valueKey: "address" },
	{ label: "dateBirth", nameKey: "userInfo.dateBirth", valueKey: "dateBirth" },
	{ label: "position", nameKey: "userInfo.position", valueKey: "position" },
	{
		label: "user_type_id",
		nameKey: "userInfo.userType",
		valueKey: (user) => userTypes[user?.user_type_id - 1]
	},
	{
		label: "date_entry",
		nameKey: "userInfo.dateEntry",
		valueKey: (user) => user?.date_entry || (user?.created_at ? moment(user.created_at).format("YYYY-MM-DD HH:mm") : null)
	},
	{ label: "nationality", nameKey: "userInfo.nationality", valueKey: "nationality" },
	{ label: "profession", nameKey: "userInfo.profession", valueKey: "profession" },
	{ label: "emergency_contact", nameKey: "userInfo.emergencyContact", valueKey: "emergency_contact" },
	{ label: "emergency_phone", nameKey: "userInfo.emergencyPhone", valueKey: "emergency_phone" },
	{
		label: "userSubBranch",
		nameKey: "userInfo.userSubBranch",
		valueKey: (user, subBranches) => {
			const subBranch = subBranches.find((sb) => String(sb.id) === String(user?.contractor_id))
			return subBranch?.name;
		}
	},
	{
		label: "userEnterprise",
		nameKey: "userInfo.userEnterprise",
		valueKey: (user, subEnterprises) => {
			const enterprise = subEnterprises.find((se) => String(se.id) === String(user?.sub_enterprise_id))
			return enterprise?.name;
		}
	},
	{
		label: "education_level",
		nameKey: "userInfo.educationLevel",
		valueKey: (user, EducationLevels) => {
			const educationLevels = EducationLevels
			return !!user?.education_level
				? educationLevels[user?.education_level - 1]?.label
				: user?.education_level
		}
	}
]
