import { VpnKey } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import useToggle from '../../Hooks/ToogleHook'
import RegisterNewSignDialog from '../Dialogs/RegisterNewSignDialog'
import { useTranslation } from 'react-i18next'

function RegisterNewSignButton({ afterRegister, disabled }) {

	const [openDialog, toggleDialog] = useToggle(false)
	const { t } = useTranslation("dialogs")

	return (
		<Box>
			<Button color="info" variant="outlined" endIcon={<VpnKey />} onClick={toggleDialog} disabled={disabled}>
				{t('actions.registerSign')}
			</Button>
			<RegisterNewSignDialog
				open={openDialog}
				toggleOpen={toggleDialog}
				afterRegister={afterRegister}
			/>
		</Box>
	)
}

export default RegisterNewSignButton