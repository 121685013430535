import { Box } from '@mui/material';
import { useLayoutEffect, useState, useRef, useMemo } from 'react';

const css = {
	container: {
		borderRadius: 1,
		display: 'flex',
		gap: 1,
		alignItems: 'center',
		position: 'absolute',
		width: '100%',
		boxSizing: 'border-box',
		overflow: 'hidden'
	}
};

function FixedHeightElement({
	height = 40,
	index,
	spacing = 12,
	children,
	updateHeight,
	heights
}) {
	const el = useRef(null);

	const computedTop = useMemo(() => {
		let total = 0;
		for (let i = 0; i < index; i++) {
			total += (heights[i] !== undefined ? heights[i] : height) + spacing;
		}
		return total;
	}, [index, height, spacing, heights[index]]);

	// Measure the element's height after layout, and update only if different.
	useLayoutEffect(() => {
		if (el.current) {
			// Round the measured height to avoid tiny fluctuations.
			const measuredHeight = Math.round(el.current.getBoundingClientRect().height);
			const currentHeight = Math.round(heights[index] !== undefined ? heights[index] : height);
			if (currentHeight !== measuredHeight) {
				updateHeight(index, measuredHeight);
			}
		}
		// Narrow dependency to just the value for this element.
	}, [index, heights[index]]);

	return (
		<Box sx={css.container} style={{ top: computedTop }} ref={el}>
			{children}
		</Box>
	);
}

export default FixedHeightElement;
