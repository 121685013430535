import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { Box, Button, Paper, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import newTheme from '../../../newTheme'
import ChecklistSignContent from '../Content/Signs/ChecklistSignContent'
import { ChecklistContext } from './ChecklistView'
import ExtraFields from '../Content/ExtraFields'
import ItemsContainer from '../Content/ItemsContainer'
import FindingsContainer from '../Content/FindingsContainer'
import './animations.css'
import ProgressBar from './Progress/ProgressBar'
import { CheckIfFieldsAreAnswered, CheckIfItemsAreAnswered, getFieldsProgress, getItemsProgress, CheckIfSignsAreReady, getIpaProgress, CheckIfIpaIsReady } from './checklistUtils'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ChecklistIPA from './ChecklistIPA'

const css = {
	container: {
		padding: 3,
		margin: '24px 0',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		maxWidth: 800,
		boxSizing: 'border-box',
		position: 'sticky',
		top: 70,
		zIndex: 10,
		transition: 'all 0.2s ease-in-out',
		'@media (max-width:680px)': {
			gap: "18px 6px",
			padding: 2,
		}
	},
	box: {
		maxWidth: 100,
		flexGrow: 1,
		height: 50,
		background: 'whitesmoke',
		borderRadius: 2,
		padding: 1,
		'@media (max-width:680px)': {
			flexBasis: '40%',
			maxWidth: 'unset',
			flexGrow: 1,
			height: 25,
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		'&:hover': {
			background: newTheme.palette.primary.light
		},
		position: 'relative'
	},
	selected: {
		background: newTheme.palette.blue.light,
		'&:hover': {
			background: newTheme.palette.blue.light
		}
	},
	main: {
		paddingBottom: 8,
	},
	navigations: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 2
	}
}

function getDefaultTabValue(checklist, permissions) {
	const hasItems = !!checklist?.checklist_items2?.length
	const hasExtraFields = !!checklist?.checklist_fields?.length
	const hasSigns = checklist?.require_signs === 1
	if (hasSigns && !permissions) return 3
	if (checklist.invert_order === 1) {
		if (hasExtraFields) return 0
		if (hasItems) return 1
	}
	if (checklist.invert_order === 0) {
		if (hasItems) return 0
		if (hasExtraFields) return 1
	}
	if (hasSigns) return 3
	return 2
}

function ContentSelector({ isPublic }) {

	const { checklist, highlighted, finished, hasPermissions } = useContext(ChecklistContext)
	const [selectedSection, setSelectedSection] = useState(0)
	const [nextSection, setNextSection] = useState(null)
	const [prevSection, setPrevSection] = useState(null)
	const [isSticky, setSticky] = useState(false)
	const enterprise = localStorage.getItem("enterprise")
	const ipa_name = enterprise ? JSON.parse(enterprise).ipa_name : "IPA"
	const ipa_percentage = enterprise ? JSON.parse(enterprise).ipa_percentage : true

	const button = useRef(null)
	const selected_content = useRef(null)
	const menu = useRef(null)

	const boxcss = css.box
	const selectedcss = { ...css.box, ...css.selected }
	const hasItems = !!checklist?.checklist_items2?.length
	const hasExtraFields = !!checklist?.checklist_fields?.length || !!checklist?.fields?.length
	const hasSigns = checklist?.require_signs === 1
	const isFormBeforeItems = checklist.invert_order === 1

	const location = useLocation()
	const { t } = useTranslation('checklistShow')

	useEffect(() => {
		const query = new URLSearchParams(location.search)
		const tab = query.get('tab', selectedSection)
		setSelectedSection(tab ? parseInt(tab, 10) : getDefaultTabValue(checklist, hasPermissions))
	}, [hasPermissions, checklist.id])

	const itemsReady = useMemo(() => CheckIfItemsAreAnswered(checklist), [checklist])
	const fieldsReady = useMemo(() => CheckIfFieldsAreAnswered(checklist), [checklist])
	const signsReady = useMemo(() => CheckIfSignsAreReady(checklist), [checklist])
	const ipaReady = useMemo(() => CheckIfIpaIsReady(checklist, ipa_percentage), [checklist])

	const itemsBeforeForm = [
		{ value: 0, name: "Checklist", item: ItemsContainer, disabled: !hasItems, progress: getItemsProgress(checklist) },
		{ value: 1, name: t('content.form'), item: ExtraFields, disabled: !hasExtraFields, progress: getFieldsProgress(checklist) },
		{ value: 2, name: t('content.findings'), item: FindingsContainer, progress: 1000, disabled: !checklist.require_finding_visibility },
		{ value: 3, name: t('content.signs'), item: ChecklistSignContent, disabled: !hasSigns, progress: 1000 },
		{ value: 4, name: ipa_name, item: ChecklistIPA, disabled: finished ? !checklist?.users_amount : !(ipa_percentage && checklist?.calculate_ipa), progress: getIpaProgress(checklist) }
	]
	const formBeforeItems = [
		{ value: 0, name: t('content.form'), item: ExtraFields, disabled: !hasExtraFields, progress: getFieldsProgress(checklist) },
		{ value: 1, name: "Checklist", item: ItemsContainer, disabled: !hasItems, progress: getItemsProgress(checklist) },
		{ value: 2, name: t('content.findings'), item: FindingsContainer, progress: 1000, disabled: !checklist.require_finding_visibility },
		{ value: 3, name: t('content.signs'), item: ChecklistSignContent, disabled: !hasSigns, progress: 1000 },
		{ value: 4, name: ipa_name, item: ChecklistIPA, disabled: finished ? !checklist?.users_amount : !(ipa_percentage && checklist?.calculate_ipa), progress: getIpaProgress(checklist) }
	]

	let finalDisplay = useMemo(() => isFormBeforeItems ? formBeforeItems : itemsBeforeForm, [isFormBeforeItems, checklist])
	if (!!isPublic) { finalDisplay = finalDisplay.slice(0, 2) }
	const ContentToRender = finalDisplay[selectedSection].item

	useEffect(() => {
		window.addEventListener("scroll", checkStickyMenu)
		return () => window.removeEventListener("scroll", checkStickyMenu)
	}, [])

	function checkStickyMenu() { setSticky(menu.current.getBoundingClientRect().top <= 70) }

	useEffect(() => {
		console.log("highlighted", highlighted)
		if (highlighted?.type === "item") { setSelectedSection(isFormBeforeItems ? 1 : 0) }
		if (highlighted?.type === "field") { setSelectedSection(isFormBeforeItems ? 0 : 1) }
		if (highlighted?.type === "ipa") { setSelectedSection(4) }
		if (itemsReady && fieldsReady && !signsReady && ipaReady) { setSelectedSection(3) }
	}, [highlighted])


	useEffect(() => {
		function checkNextSection() {
			let next_section_index = selectedSection + 1
			while (next_section_index <= 4) {
				const next_section = finalDisplay[next_section_index]
				if (!next_section?.disabled && next_section?.value !== 2) return setNextSection(next_section)
				next_section_index += 1
			}
			setNextSection(null)
		}

		function checkPrevSection() {
			let prev_section_index = selectedSection - 1
			while (prev_section_index >= 0) {
				const prev_section = finalDisplay[prev_section_index]
				if (!prev_section?.disabled && prev_section?.value !== 2) return setPrevSection(prev_section)
				prev_section_index -= 1
			}
			setPrevSection(null)
		}

		checkNextSection()
		checkPrevSection()

	}, [selectedSection, finalDisplay])


	const shouldHighligh = useMemo(() => {
		const itemsSection = isFormBeforeItems ? 1 : 0
		const formSection = isFormBeforeItems ? 0 : 1
		const ipaSection = 4

		if (itemsReady && selectedSection === itemsSection) return true
		if (fieldsReady && selectedSection === formSection) return true
		if (ipaReady && selectedSection === ipaSection) return true

		return false
	}, [selectedSection, itemsReady, fieldsReady, checklist, ipaReady])

	function goNextSection() {
		setSelectedSection(nextSection.value)
	}

	function goPrevSection() {
		setSelectedSection(prevSection.value)
	}

	useEffect(() => {
		if (shouldHighligh && !!button.current) {
			button.current.scrollIntoView({ behavior: "smooth", block: "center" })
		}
	}, [shouldHighligh, button])

	useEffect(() => {
		if (!shouldHighligh && !!selected_content.current) {
			selected_content.current.scrollIntoView({ behavior: "smooth", block: "start" })
		}
	}, [selectedSection, shouldHighligh])

	const isEmpty = finalDisplay.every(item => item.disabled)
	if (isEmpty) return null

	return (
		<Box sx={css.main} ref={selected_content}>
			<Paper sx={css.container} ref={menu} elevation={isSticky ? 7 : 0}>
				{finalDisplay.map((content, index) => {
					if (content.disabled) return null
					return (
						<>
							<Box key={index} sx={selectedSection === index ? selectedcss : boxcss} onClick={() => setSelectedSection(index)}>
								<Typography variant='h4'>{content.name}</Typography>
								<ProgressBar value={content.progress} />
							</Box>
						</>
					)
				})}
			</Paper>
			<Box>
				<ContentToRender />
			</Box>
			{!finished &&
				<Box sx={css.navigations}>
					{!!prevSection ?
						<Button color="info" variant="outlined" startIcon={<KeyboardArrowLeft />} onClick={goPrevSection}>
							{t('content.backTo', { section: prevSection?.name })}
						</Button>
						: <Box />}
					{!!nextSection &&
						<Button ref={button} className={shouldHighligh ? "highlight_border" : ""} color="primary" variant="outlined" endIcon={<KeyboardArrowRight />} onClick={goNextSection}>
							{t('content.goTo', { section: nextSection?.name })}
						</Button>
					}
				</Box>
			}
		</Box>
	)
}

export default ContentSelector