import { Box, Checkbox, Tab, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import useToggle from '../../../../Hooks/ToogleHook'
import useChangeParams from '../../../../Hooks/UseStateChangeParams'
import newTheme from '../../../../newTheme'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { addNameToB64, transformToOptionsNames } from '../../../../Utils/functions'
import WorkerSignForm from './WorkerSignForm'
import { ChecklistContext } from '../../Show/ChecklistView'
import UsersInput from '../../../../Shared/Inputs/UsersInput'
import { signChecklistWithFinger, signChecklistWithOTP } from '../../../../API/definitive_checklists'
import MySignaturePad from '../../../../Shared/Signature/MySignaturePad'
import useTabs from '../../../../Hooks/UseTabs'
import FingerSignCapture from '../../../Fingerprint/FingerSignCapture'
import { callSnackbar } from '../../../../Utils/snackbar'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'
import { getEnterprise } from '../../../../API/enterprise'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import PositionForm from '../../../Enterprise/Positions/PositionForm'
import { createPosition, getPositions } from '../../../../API/positions'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import AppContext from '../../../../AppContext'
import { CurrentUserContext } from '../../../../CurrentUser'
import EmailInput from '../../../../Shared/Inputs/EmailInput'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { updateChecklistWithNewSign } from '../utils'
import OTPSign from '../../../../Shared/OTPin/OTPSign'
import SelectInputPro from '../../../../Shared/Inputs/SelectInput/EntitySelectInput'

const css = {
	signPad: {
		display: 'flex',
		justifyContent: 'center',
	},
	input: {
		flexBasis: 200,
		flexGrow: 1
	},
	button: {
		margin: '6px 0',
		width: 250
	},
	center_checkbox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	tabs: {
		background: newTheme.palette.background.main,
		borderRadius: 1
	},
}

function SignForm({ group, signs: currentSigns }) {

	const [worker, setWorker] = useState(null)
	const [params, setParams] = useChangeParams({})
	const { checklist, setChecklist, appendSave, resolveSave, imResponsibleOfSection, hasPermissions } = useContext(ChecklistContext)
	const [isVisit, toggleVisit] = useToggle(false)
	const [tab, setTab, updateTab] = useTabs(0)
	const [allowDigital, setAllowDigital] = useState(true)
	const actionContext = useActionContext()
	const { getUsersObjects } = useContext(UserSelectContext)
	const selectedWorker = getUsersObjects(worker)
	const [openPositions, togglePositions] = useToggle(false)
	const [positions, setPositions] = useState([])
	const { online: { status: online } } = useContext(AppContext)
	const { currentUser } = useContext(CurrentUserContext)
	const { t } = useTranslation('checklistSigns')

	async function signOffline(body) {
		const new_checklist = updateChecklistWithNewSign(body, checklist, group)
		setChecklist(new_checklist)
		appendSave()
		await transformBodyAndSendCallback(body, "add_checklist_sign", actionContext, t('actions.addSign'))
		resolveSave()
	}

	async function signOTPOffline(body) {
		const new_checklist = updateChecklistWithNewSign(body, checklist, group)
		setChecklist(new_checklist)
		appendSave()
		await transformBodyAndSendCallback(body, "add_checklist_sign_otp", actionContext, t('actions.addSignOTP'))
		resolveSave()
	}

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([
				getEnterprise(),
				getPositions()
			])
			const datas = responses.map(response => response.data.info)
			updateTab(datas[0].default_signature)
			setAllowDigital(datas[0].allow_digital_sign)
			setPositions(datas[1])
		}
		fetchData()
	}, [])

	useEffect(() => {
		setWorker(null)
	}, [isVisit])

	async function onCreatePosition(body) {
		const newLocalPosition = { name: body.name, id: 0 }
		const new_positions = [...positions, newLocalPosition]
		setPositions(new_positions)
	}

	async function onSign(value, uid = null) {
		let email_worker = { ...selectedWorker }
		let user_name = selectedWorker?.name || ""
		user_name = user_name?.split("-")[0].trim()
		let rut = selectedWorker?.rut || ""
		let position = selectedWorker?.position || null
		if (isVisit) {
			user_name = params.user_name
			rut = params.rut
			email_worker = { email: params.visit_email, name: user_name }
			position = params.position
		}

		const user_id = uid || worker

		const data = {
			user_id: isVisit ? 0 : user_id,
			file: addNameToB64(value, user_name),
			sign_url: addNameToB64(value, user_name),
			name: user_name,
			sign_name: user_name,
			rut: rut,
			position: position,
			checklist_id: checklist.id,
			checklist_group_sign_id: group.id,
			user_sign: "",
			email: email_worker.email,
			date: moment().format("YYYY-MM-DD HH:mm")
		}
		data.temp_id = uuid()
		data.id = data.temp_id
		signOffline(data, email_worker)
		callSnackbar("Firma registrada", "success")
		setWorker(null)
	}

	async function onSignWithFinger(user_id) {

		const body = {
			user_id: user_id,
			checklist_id: checklist.id,
			checklist_group_sign_id: group.id,
			date: moment().format("YYYY-MM-DD HH:mm"),
			file: null,
			sign_url: null,
			is_tazkipass: true
		}

		const response = await signChecklistWithFinger(body)

		if (response.data.status === "error") return callSnackbar(t('form.warningMessage'), "warning")
		const newSign = response.data.info
		const new_checklist = updateChecklistWithNewSign(newSign, checklist, group)
		setChecklist(new_checklist)
	}

	async function onSignWithOTP(user_id) {
		const user = getUsersObjects(user_id)
		const body = {
			user_id: user_id,
			checklist_id: checklist.id,
			checklist_group_sign_id: group.id,
			date: moment().format("YYYY-MM-DD HH:mm"),
			file: null,
			sign_url: null,
			name: user?.name,
			sign_name: user?.name,
			rut: user?.rut,
		}

		signOTPOffline(body)

		// const response = await signChecklistWithOTP(body)

		// if (response.data.status === "error") return callSnackbar(t('form.warningMessage'), "warning")
		// const newSign = response.data.info
		// const new_groups = [...checklist.group_signs]
		// const new_group = { ...group }
		// const new_group_index = new_groups.findIndex(g => g.id === group.id)
		// new_group.signs.push(newSign)
		// new_groups[new_group_index] = new_group
		// const new_checklist = { ...checklist }
		// new_checklist.group_signs = new_groups
		// setChecklist(new_checklist)
	}

	function visitForm() {
		return (
			<Box sx={css.visitInputs}>
				<PositionForm open={openPositions} onClose={togglePositions} onSubmit={onCreatePosition} />
				<Box sx={css.input}>
					<TextInput value={params.user_name} onChange={setParams} label={t('form.visitorName')} name="user_name" />
				</Box>
				<Box sx={css.input}>
					<TextInput value={params.rut} onChange={setParams} label={t('form.visitorRut')} name="rut" />
				</Box>
				<Box sx={css.input}>
					<EmailInput value={params.visit_email} onChange={setParams} label={t('form.visitorEmail')} name="visit_email" />
				</Box>
				<Box sx={css.input}>
					{online ? (
						<SelectInputPro
							id={'positionQuery'}
							name={"position"}
							value={params.position}
							fetchEndpoint={getPositions}
							createEndpoint={createPosition}
							onChange={setParams}
							field='name'
							title={'position'}
							label={t('form.visitorPosition')}
						/>
					) : (
						<TextInput value={params.position} onChange={setParams} label={t('form.visitorPosition')} name="position" />
					)}
				</Box>
			</Box>
		)
	}

	const hasSelectedSigned = currentSigns.map(s => String(s.user_id)).includes(worker)
	const isCollab = currentUser.userType === 3
	const hasPriviledges = hasPermissions || imResponsibleOfSection
	const shouldSignInmediatly = (!hasPriviledges || isCollab)
	if (shouldSignInmediatly) return <WorkerSignForm group={group} onManualSign={onSign} />

	return (
		<Box>
			<Tabs sx={css.tabs} value={tab} onChange={setTab} scrollButtons="auto" variant="scrollable">
				{allowDigital && <Tab value={0} label={t('form.digitalSignature')} />}
				<Tab value={1} label={t('form.biometricSignature')} />
				<Tab value={2} label={t('form.totpSignature')} />
			</Tabs>
			{tab === 0 && (
				<Box>
					<Box sx={css.center_checkbox}>
						<Checkbox checked={isVisit} onClick={toggleVisit} />
						<Typography variant="subtitle1">{t('form.signAsVisitor')}</Typography>
					</Box>
					{isVisit ? (
						visitForm()
					) : (
						<UsersInput value={worker} onChange={(e) => setWorker(e.target.value)} label={t('form.selectSigner')} />
					)}
					<Box sx={css.signPad}>
						<MySignaturePad
							clearButton="true"
							save={onSign}
							disabled={(!worker && !isVisit) || hasSelectedSigned}
						/>
					</Box>
					{!worker && !isVisit && (
						<Typography variant="subtitle2" style={{ color: newTheme.palette.warning.main }}>
							{t('form.selectUserToSign')}
						</Typography>
					)}
					{hasSelectedSigned && (
						<Typography variant="subtitle2" style={{ color: newTheme.palette.warning.main }}>
							{t('form.userAlreadySigned')}
						</Typography>
					)}
				</Box>
			)}
			{tab === 1 && <FingerSignCapture callback={onSignWithFinger} />}
			{tab === 2 && <OTPSign callback={onSignWithOTP} />}
		</Box>
	)
}

export default SignForm