import instance2 from "../Utils/instance2"

export const getSettingsSupplies = () => {
	return instance2({
		method: 'get',
		url: 'supply_families'
	})
}

export function getSupplies(body) {
	return instance2.get('supplies', { params: body })
}

export function getSupplyHistory(body) {
	return instance2.get(`supplies/${body.id}/history`)
}

export function getSupply(body) {
	return instance2.get(`supplies/${body.id}`)
}

export function getSupplyChecklists(body) {
	return instance2.get(`supplies/${body.id}/checklists`, { params: body })
}

export function syncSupplies(body) {
	return instance2.get('supplies/sync', { params: body })
}

export function suppliesQrs(body) {
	return instance2.get('supplies/qrs', { params: body })
}

export const createSupply = body => {
	return instance2({
		method: 'post',
		url: 'supplies',
		data: body
	})
}

export function editSupply(body) {
	return instance2.put(`supplies/${body.id}`, body)
}

export function deleteSupply(body) {
	return instance2.delete(`supplies/${body.id}`)
}

export function getSupplyConsolidatedReport(body) {
	return instance2.get('supply_reports/consolidated', { params: body })
}