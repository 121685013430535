import React, { useState, useEffect, useContext } from 'react'
import { Box, IconButton, Popover } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { inline_buttons } from '../../../../Utils/defaultStyles'
import SeamlessInput from '../../SeamlessInput'
import { useConfirmationDialog } from '../../../Dialogs/ConfirmationDialog'
import { EntitySelectInputContext } from '../EntitySelectInput'

function InsideMenu({ toggleMenu, openMenu, menu, item }) {
	const [value, setValue] = useState(item.name)
	const { field, updateEndpoint, deleteEndpoint, setData, refetch } = useContext(EntitySelectInputContext)
	const askConfirmation = useConfirmationDialog()

	const handleChange = (event) => {
		const { target } = event
		const newName = target.value
		setValue(newName)
	}

	async function onDelete() {
		async function callback() {
			if (!deleteEndpoint) return
			await deleteEndpoint({ id: item.id })
			setData((oldData) => oldData.filter((currentItem) => currentItem.id !== item.id))
			refetch()
		}
		askConfirmation(null, null, null, callback)
	}

	async function onUpdate() {
		if (value === item?.[field] || !updateEndpoint) return
		await updateEndpoint({ [field]: value, id: item.id })
		const updatedItem = { ...item, [field]: value }
		setData((oldData) =>
			oldData.map((currentItem) =>
				currentItem.id === item.id ? updatedItem : currentItem
			)
		)
		refetch()
	}

	return (
		<Popover onKeyDown={(e) => e.stopPropagation()} open={openMenu} anchorEl={menu.current} onClose={toggleMenu} anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}} transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}>
			<Box sx={{ padding: 2 }}>
				<Box sx={inline_buttons}>
					{updateEndpoint &&
						<SeamlessInput
							value={value}
							onChange={handleChange}
							onBlur={onUpdate}
						/>
					}
					{deleteEndpoint &&
						<IconButton size="small" onClick={onDelete} disabled={!item?.deletable || false}>
							<Delete color={!item?.deletable ? 'grey' : 'error'} />
						</IconButton>
					}
				</Box>
			</Box>
		</Popover>
	)
}

export default InsideMenu