import instance2 from "../Utils/instance2"

export function getFindingType(body) {
	return instance2.get("finding_types", { params: body })
}

export function editFindingType(body) {
	return instance2.put(`finding_types/${body.id}`, body)
}

export function deleteFindingType(body) {
	return instance2.delete(`finding_types/${body.id}`, { data: body })
}

export function createFindingType(body) {
	return instance2.post(`finding_types`, body)
}