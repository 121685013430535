import { AddBoxOutlined, Clear, Edit, InfoOutlined, Settings } from '@material-ui/icons'
import { Box, IconButton, Paper, Slide, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import { centered_container, end_flex_buttons, inline_buttons, inline_space_start_no_wrap, paper_style, text_space, title_style } from '../../../Utils/defaultStyles'
import { ChecklistContext } from './ChecklistView'
import { editChecklist } from '../../../API/new_checklists'
import ChecklistHistory from './ChecklistHistory'
import ComplianceAndProgress from './Progress/ComplianceAndProgress'
import AppContext from '../../../AppContext'
import newTheme from '../../../newTheme'
import ChecklistAttachments from './ChecklistAttachments'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import IfOnline from '../../../Shared/OnlineStatusRendered/IfOnline'
import { downloadChecklist } from '../../../API/definitive_checklists'
import EntityTitle from '../../../Shared/EntityTitle'
import DownloadPdfButton from '../../../Shared/Buttons/DownloadPdfButton'
import RelatedChecklistDialog from './RelatedChecklists/RelatedChecklistDialog'
import BigTooltip from '../../../Shared/Tooltip/BigTooltip'
import { useTranslation } from 'react-i18next'
import { downloadFromMethod } from '../../../Utils/functions'
import moment from 'moment'
import EditChecklistDialog from './EditChecklistDialog'
import { ReportGmailerrorred } from '@mui/icons-material'
import { parseIpaRules, evaluateIpaColor } from './checklistUtils'

const css = {
	section: {
		margin: '24px 0',
		...paper_style
	},
	invalidChip: {
		borderRadius: 3,
		padding: "6px 16px",
		border: `2px solid ${newTheme.palette.error.dark}`,
		...centered_container,
		margin: '12px 0',
		gap: 1
	}
}

function SecondaryInfo({ disableButtons }) {
	const { checklist, setChecklist, hasPermissions, isAdmin, finished } = useContext(ChecklistContext)
	const [openEdit, toggleEdit] = useToggle(false)
	const [params, setParams, updateParams] = useChangeParams({})
	const { online: { status: online } } = useContext(AppContext)
	const [openExtra, toggleExtra] = useToggle(false)
	const [editing, toggleEditing] = useToggle(false)
	const [openCreateRelated, toggleOpenCreateRelated] = useToggle(false)
	const isCommittee = checklist.is_committee
	const isInvalid = checklist?.valid_until && moment(checklist.valid_until).isBefore(moment(), "day")
	const ipaRules = useMemo(() => parseIpaRules(checklist?.ipa_color_rules, finished), [checklist?.ipa_color_rules])
	const ipaColor = useMemo(() => evaluateIpaColor(checklist?.ipa, ipaRules, finished), [checklist?.ipa, ipaRules])
	const enterprise = localStorage.getItem("enterprise")
	const ipa_name = enterprise ? JSON.parse(enterprise).ipa_name : "IPA"

	const { t } = useTranslation('checklistShow')

	useEffect(() => {
		const element = document.getElementById('html-description')
		updateParams({ ...checklist })
		if (!!element) {
			element.innerHTML = checklist?.preventive_measure_description
		}
	}, [checklist, updateParams])

	async function onSave() {
		const body = { ...params }
		const response = await editChecklist(body)
		setChecklist(response.data.info)
		toggleEdit(false)
		toggleEditing(false)
	}

	async function onDownload(force = false) {
		let pdf_url = checklist.pdf_url
		if (!pdf_url || force) {
			const name = `${checklist.date_done !== "0000-00-00" ? moment(checklist.date_done).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY")}-${checklist.name.replaceAll(".", "-")}-${checklist.branch_name}.pdf`
			const body = { id: checklist.id, force }
			await downloadFromMethod(downloadChecklist, body, name)
			return
		}
		window.open(pdf_url, '_blank')
	}

	return (
		<Box>
			<Box sx={inline_space_start_no_wrap}>
				<Box>
					<Box sx={title_style}>
						<Box sx={inline_buttons}>
							<EntityTitle title={editing ? checklist?.base_name : checklist?.name} variant="h1" belongs={isCommittee} />
							{checklist?.rename && online &&
								<Box sx={inline_buttons}>
									<Box>
										{!editing && <IconButton onClick={toggleEditing} >
											<Edit />
										</IconButton>
										}
										{editing &&
											<SeamlessInput
												placeholder={t('secondaryInfo.identifierPlaceholder')}
												variant="h1"
												color={newTheme.palette.grey.main}
												value={params.lastname}
												onChange={setParams}
												name="lastname"
												onBlur={onSave}
											/>
										}
									</Box>
									{editing &&
										<IconButton onClick={toggleEditing} >
											<Clear />
										</IconButton>
									}
								</Box>
							}
						</Box>
					</Box>
					{checklist?.correlative > 0 && <Typography variant='subtitle2'>{t('secondaryInfo.correlative', { value: checklist?.correlative })}</Typography>}
					<Typography variant='subtitle1'>{checklist?.date_scheduled}</Typography>
					<Typography variant='subtitle1'>{checklist?.preventive_measure_revision} {checklist?.preventive_measure_code}</Typography>
					<Typography variant='caption'>{checklist?.activity}</Typography>
				</Box>
				<Box>
					{!disableButtons &&
						<Box sx={end_flex_buttons}>
							<IfOnline>
								<DownloadPdfButton
									onClick={onDownload}
									type={"checklist"}
									finished={finished}
									updateState={setChecklist}
									element={checklist} />
							</IfOnline>
							{checklist?.related_base_checklists?.length > 0 &&
								<BigTooltip title={t('relatedChecklists.create')} >
									<IconButton onClick={toggleOpenCreateRelated} >
										<AddBoxOutlined />
									</IconButton>
								</BigTooltip>
							}
							<IconButton onClick={toggleExtra} >
								<InfoOutlined />
							</IconButton>
							{isAdmin && online &&
								<IconButton onClick={toggleEdit} >
									<Settings />
								</IconButton>
							}
							{hasPermissions && online &&
								<ChecklistAttachments />
							}
						</Box>
					}
					{isInvalid &&
						<Box sx={css.invalidChip}>
							<Typography variant="h1" color={newTheme.palette.error.dark} >
								{t('checklistView.status.notValidChecklist')}
							</Typography>
							<ReportGmailerrorred fontSize="large" sx={{ color: newTheme.palette.error.dark }} />
						</Box>
					}
				</Box>
			</Box>
			{checklist?.preventive_measure_description &&
				<Box sx={css.section}>
					<Typography variant='h4'>{t('secondaryInfo.description')}</Typography>
					<Box className="ck-content" id="html-description"></Box>
				</Box>
			}
			{(checklist?.ipa && !checklist?.users_amount && finished) &&
				<Paper sx={{ ...paper_style, ...text_space }}>
					<Box sx={inline_buttons}>
						<Typography variant='subtitle1'>{ipa_name}: </Typography>
						<Typography color={ipaColor} variant='subtitle1' fontWeight={600}>{checklist?.ipa}</Typography>
					</Box>
				</Paper>
			}
			<EditChecklistDialog open={openEdit} onClose={toggleEdit} params={params} setParams={setParams} onSave={onSave} />
			<RelatedChecklistDialog open={openCreateRelated} onClose={toggleOpenCreateRelated} />
			<Slide in={openExtra} unmountOnExit direction='right'>
				<Box sx={text_space} >
					<Paper sx={{ ...paper_style, ...text_space }}>
						<Typography variant='subtitle1'>{t('secondaryInfo.responsible')}: <strong>{checklist?.user || t('secondaryInfo.noResponsible')}</strong></Typography>
					</Paper>
					<IfOnline>
						{hasPermissions && <ChecklistHistory />}
					</IfOnline>
					<ComplianceAndProgress />
				</Box>
			</Slide>
		</Box>
	)
}

export default SecondaryInfo