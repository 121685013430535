import { useEffect, useState } from "react";

function useDebounce(cb, delay) {
	const [debounceValue, setDebounceValue] = useState(cb);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebounceValue(cb);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [cb, delay]);
	return debounceValue;
}

let callback_timeout = null

export function useDebounceFunctionCallback(delay) {

	function debouncingCallback(callback) {
		if (!!callback_timeout) { clearTimeout(callback_timeout) }
		callback_timeout = setTimeout(() => {
			callback()
		}, delay)
	}

	return debouncingCallback

}