import { Box, Button, Typography } from '@mui/material'
import { FindingDialogContext } from '../FindingDialog'
import { useContext, useMemo, useRef } from 'react'
import newTheme from '../../../../newTheme'
import MultipleImageContainer from '../../../../Shared/Containers/MultipleImageContainer'
import { inline_space, italic_link, title_style } from '../../../../Utils/defaultStyles'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import { getLocalCrits } from '../../../../Utils/functions'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import NewDateTimeInput from '../../../../Shared/Inputs/NewDateTimeInput'
import useToggle from '../../../../Hooks/ToogleHook'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../../../CurrentUser'
import CritsMenu from './CritsMenu'

const css = {
	finding: {
		background: newTheme.palette.background.main,
		padding: 2,
		borderRadius: 2,
		margin: '12px 0',
	},
	info_line: {
		display: 'flex',
		alignItems: 'center',
		gap: 1
	}
}

function FinalStep() {

	const { params, prevStep, finding_types, onSubmit, onClose, setParams, onReset, checklist, item } = useContext(FindingDialogContext)
	const { getUsersNames } = useContext(UserSelectContext)
	const [openCrits, toggleCrits] = useToggle(false)
	const crits = getLocalCrits()
	const critsMenu = useRef(null)
	const { t } = useTranslation("findingCreationDialog")
	const images = params.free_file ? params.free_file.split("&#&") : []
	const evidence_file = params.evidence_pdf_url ? params.evidence_pdf_url : null
	const finding_type = finding_types.find(ft => ft.id === params.finding_type_id)
	const responsible = getUsersNames(params.user_id)
	const notified = getUsersNames(params.notified_users)

	const { isAdmin } = useContext(CurrentUserContext)

	function onChange(event) {
		const { name, value } = event.target
		setParams(p => ({ ...p, [name]: value }))
	}

	function onCreate() {
		const body = { ...params }
		body.user_responsible = responsible
		body.checklist_id = checklist?.id
		body.checklist_item_id = item?.id
		onSubmit(body)
		onClose()
		onReset()
	}

	const [file_base64, file_name] = evidence_file
		? evidence_file.split("|&|")
		: [null, null]


	const crit = useMemo(() => crits.find(c => c.value === params.free_criticality), [params.free_criticality, crits])
	const hasCriticality = useMemo(() => crit.value !== 0, [crit])
	return (
		<Box>
			<Typography variant='h4' color="info.main">{t('messages.almostReady')}</Typography>
			<Typography variant='subtitle1'>{t('messages.informationSummary')}</Typography>
			<CritsMenu crits={crits} openCrits={openCrits} toggleCrits={toggleCrits} critsMenu={critsMenu} setParams={setParams} params={params} />
			<Box sx={css.finding}>
				<Typography variant='h4' sx={title_style} >{params.free_description}</Typography>
				<Box sx={css.info_line}>
					<Typography variant='subtitle1'>{t('data.criticality')}:</Typography>
					<Typography variant='subtitle2'
						onClick={(hasCriticality && !isAdmin) ? null : toggleCrits}
						ref={critsMenu}
						sx={(hasCriticality && !isAdmin) ? "" : italic_link} >{crit?.label}</Typography>
				</Box>
				<Box sx={css.info_line}>
					<Typography variant='subtitle1'>{t('data.responsible')}:</Typography>
					<Typography variant='subtitle2'>{responsible || "No asignado"}</Typography>
				</Box>
				{!!finding_type &&
					<Box sx={css.info_line}>
						<Typography variant='subtitle1'>{t('data.findingType')}:</Typography>
						<Typography variant='subtitle2'>{finding_type?.name}</Typography>
					</Box>
				}
				<Box sx={css.info_line}>
					<Typography variant='subtitle1'>{t('data.inmediateAction')}:</Typography>
					<Typography variant='subtitle2'>{params.description}</Typography>
				</Box>
				<Box sx={css.info_line}>
					<Typography variant='subtitle1'>{t('data.dateLimit')}:</Typography>
					<NewDateTimeInput pretty onChange={onChange} name="date_limit" value={params.date_limit} maxDate={params.maxDate} />
				</Box>
				{evidence_file &&
					<Box sx={css.info_line}>
						<Typography variant='subtitle1'>{t('data.evidenceDocument')}:</Typography>
						<Typography variant='subtitle2'>{file_name}</Typography>
					</Box>
				}
				<Box sx={css.info_line}>
					<Typography variant='subtitle1'>{t('data.ocurrencesNumber')}:</Typography>
					<SeamlessInput padding={0} name="counter" value={params.counter} onChange={onChange} variant="subtitle2" color="transparent" fontColor={newTheme.palette.info.main} />
				</Box>
				{!!notified &&
					<Box sx={css.info_line}>
						<Typography variant='subtitle1'>{t('data.additionalNotifications')}:</Typography>
						<Typography variant='subtitle2'>{notified}</Typography>
					</Box>
				}
				<MultipleImageContainer images={images} grid height={128} width={128} border="6px" />
			</Box>
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				<Button variant="contained" color="success" onClick={onCreate} >
					{t('actions.saveAndExit')}
				</Button>
			</Box>
		</Box >
	)
}

export default FinalStep